<template>
    <div class="container-fluid m-0 p-0" id="home">
        <img :src="mnmap" alt="Imagen de fondo" style="position: fixed;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;opacity: 0.05;z-index: -1;">
        <div class="row-fluid">
            <div class="col-12 d-flex">
                <div class="d-flex mt-5 text-md-start text-center mx-5">
                    <h2 class="mt-5 lineup title-sm">Viaja a cualquier lugar</h2>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex justify-content-center m-md-5 m-3 text-center">
                    <h3 class="m-2 lineup description-md">Ya sea que necesites ir al trabajo, de compras o salir con amigos, 
                    nuestro servicio de viaje en Chihuahua está listo para llevarte a tu destino de manera rápida y eficiente. 
                    Descarga la aplicación hoy y experimenta la nueva forma de moverte por la ciudad. ¡Tu comodidad es nuestra prioridad!
                    </h3>
                </div>
            </div>
            <div class="row-fluid m-md-5 m-3">
                <div class="col-12">
                    <img class="img-fluid" :src="mainopen">
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-12">
                    <div class="d-flex justify-content-center m-md-5 m-3 text-center">
                        <h3 class="m-2 lineup description-md">Nuestro servicio de transporte está diseñado para brindarte comodidad, 
                        seguridad y eficiencia en cada viaje. Ya no tienes que preocuparte por esperar en la calle o buscar un taxi; 
                        con nuestra aplicación fácil de usar, puedes solicitar un viaje con solo unos clics.
                        </h3>
                    </div>
                </div>
            </div>
            <div class="row-fluid m-md-5 m-3">
                <div class="col-12">
                    <Carousel :items-to-show="5" :wrap-around="true" :autoplay="4000">
                        <Slide v-for="(homesli, index) in galleryphone" :key="index">
                        <div class="m-1">
                        <img class="img-fluid imghover4" :src="homesli.img">
                        </div>
                        </Slide>

                        <template #addons>
                        </template>
                    </Carousel>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-12">
                    <div class="d-flex justify-content-center m-md-5 m-3 text-center">
                        <h4 class="m-2 description-md">
                            Día con día estamos expandiendo nuestros servicios para todos los usuarios de la Ciudad de Chihuahua.
                            <br><br>
                            Nuestras próximas aperturas:<br>
                            Av Imperio<br>
                            Sahuaros<br>
                            Palmas<br>
                            Pistolas Meneses<br>
                            Desarrollo Industrial<br>
                            Vida Digna<br>
                            Ponce de León
                        </h4>
                    </div>
                </div>
            </div>
            <cc-apps></cc-apps>
        </div>
    </div>

</template>
<script setup>
    import { ref } from 'vue'
    import { Carousel, Navigation, Slide } from 'vue3-carousel'

    const galleryphone = ref([ 
        {img: ref(require("ASSETS/images/openings/open1.webp"))}, 
        {img: ref(require("ASSETS/images/openings/open2.webp"))}, 
        {img: ref(require("ASSETS/images/openings/open3.webp"))},
        {img: ref(require("ASSETS/images/openings/open4.webp"))}, 
        {img: ref(require("ASSETS/images/openings/open5.webp"))}
    ])

    const mnmap = require("ASSETS/images/bgmap")

    const mainopen = require("ASSETS/images/openings/openmain.webp")

</script>

<style scoped lang="scss">
</style>
