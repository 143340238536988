import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

global.CONFIG = require('./page.config.js')

// import CUSTOM
import './assets/scss/custom.scss'

import 'vue3-carousel/dist/carousel.css'
// import GLOBAL COMPONENTS
import { Navbar, Apps, Footer } from './components/index.js'

// imports LIBS
//import _Request from './libs/sendRequest.js'
//import _redirect from './libs/redirect.js'


// TEMPLATES
import Tdefault from 'TEMPLATES/Tdefault'

// Create App
const app = createApp(App)
app.use(store)
app.use(router)

// Templates
app.component('default', Tdefault)

// Components
app.component('cc-navbar', Navbar)
app.component('cc-apps', Apps)
app.component('cc-footer', Footer)
// Libs

// Mount
app.mount('#app')
