import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Contact from '../views/Contact.vue'
import Download from '../views/Download.vue'
import Driver from '../views/Driver.vue'
import Operation from '../views/Operation.vue'
import Services from '../views/Services.vue'
import Us from '../views/Us.vue'
import Openings from "../views/Openings.vue";
import Notice from '../views/Notice.vue'
import Terms from '../views/Terms.vue'
//Home Contact Download Driver Operation Services
const routes = [
    {
        path: '/',
        name: 'home',
        meta: {
            layout: 'default'
        },
        component: Home
    },
    {
        path: '/contacto',
        name: 'contact',
        meta: {
            layout: 'default'
        },
        component: Contact
    },
    {
        path: '/descarga',
        name: 'download',
        meta: {
            layout: 'default'
        },
        component: Download
    },
    {
        path: '/conductor',
        name: 'driver',
        meta: {
            layout: 'default'
        },
        component: Driver
    },
    {
        path: '/operacion',
        name: 'operation',
        meta: {
            layout: 'default'
        },
        component: Operation
    },
    {
        path: '/servicios',
        name: 'services',
        meta: {
            layout: 'default'
        },
        component: Services
    },
    {
        path: '/nosotros',
        name: 'us',
        meta: {
            layout: 'default'
        },
        component: Us
    },
    {
        path: '/aperturas',
        name: 'openings',
        meta: {
            layout: 'default'
        },
        component: Openings
    },
    {
        path: '/aviso-de-privacidad',
        name: 'notice',
        meta: {
            layout: 'default'
        },
        component: Notice
    },
    {
        path: '/terminos',
        name: 'terms',
        meta: {
            layout: 'default'
        },
        component: Terms
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
