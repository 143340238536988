<template>
    <div class="container-fluid m-0 p-0" id="home">
        <img :src="mnmap" alt="Imagen de fondo" style="position: fixed;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;opacity: 0.05;z-index: -1;">
        <div class="row-fluid">
            <div class="col-12 d-flex">
                <div class="d-flex title-sm mt-5 text-start mx-5">
                    <div class="mt-5 lineup">Maneja con Caddy Conductor</div>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex justify-content-center description-md m-md-5 m-3 text-center">
                    <div class="m-2 lineup">¡Bienvenido a la oportunidad de conducir con la aplicación Caddy! Con nosotros, 
                    tienes la flexibilidad para generar ingresos de manera independiente y adaptarte a tu propio horario. Ya sea que estés buscando una alternativa 
                    a un empleo de tiempo completo, un trabajo de medio tiempo o simplemente desees complementar tus ingresos como conductor de aplicación, 
                    Caddy es la plataforma para ti.
                    </div>
                </div>
            </div>
            <div class="row-fluid m-md-5 m-3">
                <div class="col-12">
                    <div class="col-12">
                        <img class="img-fluid" :src="maindriver">
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex title-sm m-md-5 m-3 text-start">
                    <div class="m-2 lineup">Beneficios de Conducir con Caddy:
                    </div>
                </div>
                <div class="d-flex align-items-center description-sm m-md-5 m-3 text-start ">
                    <img :src="logoblue" class="m-2" style="max-height: 60px;"><div class="m-2"><b class="description-md">1. Flexibilidad Horaria:</b><br>
                        Tú decides cuándo y cuánto tiempo quieres conducir. No hay horarios fijos, lo que te permite adaptar tu trabajo a tu estilo de vida.
                    </div>
                </div>
                <div class="d-flex align-items-center description-sm m-md-5 m-3 text-start ">
                    <img :src="logoblue" class="m-2" style="max-height: 60px;"><div class="m-2"><b class="description-md">2. Oportunidades de Ingresos Adicionales:</b><br>
                        Convierte tu automóvil en una fuente de ingresos adicional. Caddy te brinda 
                        la oportunidad de maximizar tus ganancias mientras trabajas según tus necesidades financieras.
                    </div>
                </div>
                <div class="d-flex align-items-center description-sm m-md-5 m-3 text-start ">
                    <img :src="logoblue" class="m-2" style="max-height: 60px;"><div class="m-2"><b class="description-md">3. Trabaja como Socio Conductor:</b><br>
                        Si ya eres un conductor para otras aplicaciones, considera convertirte en un socio conductor de
                            Caddy para diversificar tus fuentes de ingresos y aprovechar al máximo tu tiempo detrás del volante.
                    </div>
                </div>
                <div class="d-flex align-items-center description-sm m-md-5 m-3 text-start ">
                    <img :src="logoblue" class="m-2" style="max-height: 60px;"><div class="m-2"><b class="description-md">4. Fácil Integración:</b><br>
                        La aplicación Caddy es fácil de usar y está diseñada para una experiencia sin complicaciones. 
                        Desde la aceptación de viajes hasta el proceso de pago, todo se realiza de manera sencilla y eficiente.
                    </div>
                </div>
                <div class="d-flex align-items-center description-sm m-md-5 m-3 text-start ">
                    <img :src="logoblue" class="m-2" style="max-height: 60px;"><div class="m-2"><b class="description-md">5. Soporte al Conductor:</b><br>
                        Caddy se preocupa por sus socios conductores. Ofrecemos un sólido sistema de 
                        soporte para responder a tus preguntas y ayudarte en cada paso del camino.
                    </div>
                </div>
            </div>

            <div class="row-fluid m-md-5 m-3">
                <div class="col-12">
                    <Carousel :items-to-show="3" :wrap-around="true" :autoplay="4000">
                        <Slide v-for="(homesli, index) in galleryphone2" :key="index">
                        <div class="m-1">
                        <img class="img-fluid imghover4" :src="homesli.img">
                        </div>
                        </Slide>

                        <template #addons>
                        </template>
                    </Carousel>
                </div>
            </div>

            <div class="col-12">
                <div class="d-flex title-sm m-md-5 m-3 text-start">
                    <div class="m-2 lineup">Como comenzar:
                    </div>
                </div>
                <div class="d-flex align-items-center description-sm m-md-5 m-3 text-start ">
                    <img :src="logoblue" class="m-2" style="max-height: 60px;"><div class="m-2"><b class="description-md">1. Registro Sencillo:</b><br>
                        Descarga la aplicación Caddy y regístrate como socio conductor..
                    </div>
                </div>
                <div class="d-flex align-items-center description-sm m-md-5 m-3 text-start ">
                    <img :src="logoblue" class="m-2" style="max-height: 60px;"><div class="m-2"><b class="description-md">2. Verificación Rápida:</b><br>
                        Completa el proceso de verificación, que generalmente es rápido y eficiente.
                    </div>
                </div>
                <div class="d-flex align-items-center description-sm m-md-5 m-3 text-start ">
                    <img :src="logoblue" class="m-2" style="max-height: 60px;"><div class="m-2"><b class="description-md">3. Configuración de Perfil:</b><br>
                        Personaliza tu perfil, establece tus preferencias y carga la documentación necesaria.
                    </div>
                </div>
                <div class="d-flex align-items-center description-sm m-md-5 m-3 text-start ">
                    <img :src="logoblue" class="m-2" style="max-height: 60px;"><div class="m-2"><b class="description-md">4. Establece tu Disponibilidad:</b><br>
                        Elige cuándo deseas estar disponible para aceptar viajes.
                    </div>
                </div>
                <div class="d-flex align-items-center description-sm m-md-5 m-3 text-start ">
                    <img :src="logoblue" class="m-2" style="max-height: 60px;"><div class="m-2"><b class="description-md">5. Comienza a Conducir:</b><br>
                        Recibe solicitudes de viaje y comienza a generar ingresos de inmediato.
                    </div>
                </div>
            </div>
            <div class="row-fluid m-md-5 m-3">
                <Carousel :items-to-show="3" :wrap-around="true" :autoplay="4000">
                        <Slide v-for="(homesli, index) in galleryphone" :key="index">
                        <div class="m-1">
                        <img class="img-fluid imghover4" :src="homesli.img">
                        </div>
                        </Slide>

                        <template #addons>
                        </template>
                    </Carousel>
            </div>

            <div class="row-fluid">
                <div class="col-12">
                    <div class="d-flex justify-content-center description-md m-md-5 m-3 text-center">
                        <div class="m-2">Caddy te ofrece una forma flexible y rentable de ganar dinero conduciendo. 
                        Únete a nosotros y sé parte de una comunidad de conductores comprometidos a brindar un excelente servicio. 
                        ¡Es hora de poner en marcha tu viaje con Caddy!
                        </div>
                    </div>
                </div>
            </div>
            <cc-apps></cc-apps>
        </div>
    </div>

</template>
<script setup>
    import { ref } from 'vue'
    import { Carousel, Navigation, Slide } from 'vue3-carousel'

    const galleryphone = ref([ 
        {img: ref(require("ASSETS/images/driver/driver1.webp"))}, 
        {img: ref(require("ASSETS/images/driver/driver2.webp"))}, 
        {img: ref(require("ASSETS/images/driver/driver3.webp"))}
    ])

    const galleryphone2 = ref([ 
        {img: ref(require("ASSETS/images/driver/driver4.webp"))}, 
        {img: ref(require("ASSETS/images/driver/driver5.webp"))}, 
        {img: ref(require("ASSETS/images/driver/driver6.webp"))}
    ])

    const mnmap = require("ASSETS/images/bgmap")
    const maindriver = require("ASSETS/images/driver/mndriver.webp")
    const logoblue = require("ASSETS/images/home/caddyblue.webp")



</script>

<style scoped lang="scss">
</style>
