<template>
    <div class="container-fluid m-0 p-0" id="home">
        <img :src="mnmap" alt="Imagen de fondo" style="position: fixed;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;opacity: 0.05;z-index: -1;">
        <div class="row-fluid">
            <div class="col-12 d-flex">
                <div class="d-flex title-sm mt-5 text-md-start text-center mx-5 text-white">
                    <div class="mt-5 lineup" style="text-shadow: #672683 1px 0 20px;">Términos y condiciones</div>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex justify-content-center description-xs m-md-3 m-1 text-center flex-column" >
                    <div class="m-2 lineup text-start"><b>TÉRMINOS Y CONDICIONES </b><br>
                        En este aviso de privacidad, se describe la forma en la que Caddy Cab México S de RL de C.V. 
                        Río Ebro 2020 Ribeeras del sacramento número 2020, C.P 31184, contacto@caddy.com.mx, teléfono 614 470 8296, recopila y usa los datos.
                        Este aviso de privacidad aplica a todos los usuarios de Caddy Cab, aplicaciones, sitios web y otros servicios de Caddy Cab. 
                        Este aviso se aplica específicamente a los siguientes usuarios: Pasajeros: personas que solicitan, utilizan la aplicación o 
                        el servicio para solicitar y utilizar el transporte ofrecido o servicios relacionados a través de su cuenta de Caddy Cab. 
                        Conductores: personas u operadores del vehículo que brindan el servicio de transporte a los pasajeros de forma individual a 
                        través de su cuenta de Caddy Cab o mediante empresas de transporte asociadas. Todas las personas a las que se les aplica este 
                        aviso se denominará "usuarios". 
                    </div><br>
                    <div class="m-2 lineup text-start"><b>Relación Contractual - Condiciones de Uso</b><br>
                        Al aceptar estas Condiciones, usted acuerda estar legalmente vinculado por ellas y reconoce que establecen una relación contractual entre usted y Caddy Cab México S de 
                        RL del C.V. Si no acepta estas condiciones, no podrá acceder ni utilizar los Servicios. Estas Condiciones rigen su acceso y uso de los servicios proporcionados por Caddy 
                        Cab México S de RL del C.V., incluyendo aplicaciones, páginas web, contenido, productos y servicios (en adelante, "Servicios"). La entidad proveedora, con domicilio en Río 
                        Ebro 2020 Ribeeras del Sacramento número 2020, C.P 31184, está disponible para ser contactada a través de contacto@caddy.com.mx o al teléfono 614 470 8296. Al acceder o 
                        utilizar los Servicios, usted acepta cumplir con estas Condiciones, independientemente de su ubicación geográfica. Caddy Cab México S de RL del C.V. se reserva el derecho 
                        de poner fin a estas Condiciones o de dejar de ofrecer o negar el acceso a los Servicios, ya sea en su totalidad o en parte, en cualquier momento y por cualquier motivo. 
                        Se le informará previamente, a menos que se vean afectados derechos válidamente adquiridos por usted. En el caso de actividades promocionales u otros beneficios específicos,
                        pueden aplicarse Condiciones Suplementarias. Estas se comunicarán con antelación para su aceptación, y usted tiene el derecho de negarse a participar. En caso de conflicto, 
                        las Condiciones Suplementarias prevalecerán sobre estas Condiciones.
                    </div><br>
                    <div class="m-2 lineup text-start"><b>Descripción de los Servicios</b><br>
                        Los Servicios de Caddy Cab comprenden la disponibilidad de una plataforma tecnológica a través de aplicaciones móviles o páginas web (cada una denominada "Aplicación") que permiten a los usuarios organizar y planificar 
                        servicios de transporte, logística, compra y entrega de bienes. Estos servicios están sujetos a disponibilidad en el territorio donde usted se encuentre y se ofrecen conforme a un acuerdo con Caddy Cab. Es importante 
                        destacar que los Servicios se brindan exclusivamente para uso personal y no comercial.
                        Usted reconoce que Caddy Cab no proporciona servicios de transporte, logística, compra y entrega de bienes, ni opera como una empresa de transporte. Además, los Terceros Proveedores no son empleados de Caddy Cab.
                    </div><br>
                    <div class="m-2 lineup text-start"><b>Prestación de los Servicios</b><br>
                        1. Disponibilidad y Opciones: Los Servicios y otras opciones pueden estar disponibles bajo diversas marcas o categorías de pedidos, ya sea a través de las aplicaciones 
                        móviles de Caddy o las páginas web pertinentes. También pueden ser facilitados por terceros proveedores independientes, como conductores de redes de transporte, 
                        titulares de permisos de transporte, o aquellos con licencias similares.
                        <br>2. Servicios y Contenido de Terceros: Los Servicios pueden estar accesibles a través de servicios y contenido de terceros, incluyendo publicidad, que no son 
                        proporcionados ni controlados por Caddy Cab México S de RL del C.V. Usted reconoce que pueden aplicarse condiciones y políticas diferentes al utilizar estos 
                        servicios y contenido de terceros. Caddy Cab México S de RL del C.V. no respalda dichos servicios y contenido, y no asume responsabilidad por productos o servicios de terceros.
                        <br>3. Terceros Beneficiarios: Terceros que ofrezcan productos o servicios similares, así como sus subsidiarias o afiliados internacionales, serán terceros beneficiarios de este contrato si acceden a los Servicios mediante aplicaciones desarrolladas para dispositivos iOS, Android, respectivamente. Estos terceros beneficiarios no son partes de este contrato y no tienen responsabilidad en la prestación o apoyo de los Servicios.
                        <br>4. Titularidad: Los Servicios y sus derechos son propiedad de Caddy Cab México S de RL del C.V. o de sus licenciantes. El uso de los Servicios no le concede ningún derecho sobre estos, 
                        excepto la licencia limitada previamente otorgada. No se le permite utilizar los nombres, logotipos, marcas comerciales o de servicio de Caddy Cab México S de RL del C.V.
                    </div><br>
                    <div class="m-2 lineup text-start"><b>Uso de los Servicios</b><br>
                        a. Cuentas de Usuario y Datos Personales: Para aprovechar la mayoría de los aspectos de los Servicios, debe registrar y mantener activa una cuenta personal de usuario ("Cuenta"). Usted es responsable de toda la actividad en su Cuenta, y debe mantener la información precisa y segura. Incumplir esto puede resultar en la imposibilidad de acceder a los Servicios y en la terminación de estas Condiciones por parte de Caddy Cab.
                        <br>b. Limitaciones de Cuenta: Solo se permite poseer una Cuenta, y debe tener al menos 18 años o la mayoría de edad legal en su jurisdicción para registrarla, a menos que se indique lo contrario. El registro requiere compartir ciertos datos personales con Caddy Cab.
                    </div><br>
                    <div class="m-2 lineup text-start"><b>Requisitos y Conducta del Usuario</b><br>
                        1. Autorización y Responsabilidades de la Cuenta: A menos que se informe expresamente lo contrario, 
                        no está autorizado a permitir que terceros utilicen su Cuenta. Además, no puede permitir que personas menores de 18 años reciban servicios de transporte 
                        o logísticos de Terceros Proveedores, a menos que estén acompañadas por usted o tengan su autorización expresa. No está permitido ceder o transferir su cuenta a otra persona o entidad.
                        <br>2. Conducta y Cumplimiento Legal: Al acceder y utilizar los Servicios, se compromete a actuar de buena fe y de manera razonable. Debe cumplir con todas las leyes aplicables y utilizar 
                        los Servicios con fines legítimos, evitando el transporte de materiales ilegales o peligrosos. No deberá causar molestias, incomodidades o daños a la propiedad, 
                        ya sea al Tercero Proveedor o a cualquier otra parte.
                        <br>3. Verificación de Identidad: Podrá solicitarse que proporcione un documento de identidad u otro elemento de verificación para acceder o utilizar los Servicios. El acceso o uso de los Servicios puede ser denegado si se niega a facilitar dicha información. En caso de no poder realizar la entrega de productos debido a la falta de documentación, se generarán cargos adicionales a su cuenta.
                        <br>4. Mensajes de Texto: Al crear una Cuenta, acepta recibir mensajes de texto de Caddy Cab México S de RL del C.V, ya sea directamente o a través de aplicaciones de mensajería autorizadas de terceros, como parte de la actividad comercial normal relacionada con el uso de los Servicios. Puede optar por no recibir estos mensajes informando a Caddy Cab México S de RL del C.V a través de contacto@caddy.com.mx.
                        <br>5. Acceso a la Red y Dispositivos: Es su responsabilidad obtener el acceso a la red de datos necesaria para utilizar los Servicios. Puede aplicarse tarifas y tasas de datos y mensajes de su red móvil, y usted será responsable de dichos costos. También es su responsabilidad adquirir y actualizar el hardware o dispositivos necesarios para acceder y utilizar los Servicios y la Aplicación.
                    </div><br>
                    <div class="m-2 lineup text-start"><b>Pago</b><br>
                        1. Cargos por Servicios: Al utilizar los Servicios, usted acepta que pueden aplicarse Cargos por los servicios o bienes recibidos de Terceros Proveedores, terceros independientes o de Caddy Cab México S de RL del C.V ("Cargos"). Estos Cargos pueden incluir tarifas de solicitud, recargos aeroportuarios, tarifas municipales, estatales o nacionales/federales, tarifas de procesamiento de pagos fraccionados, entre otros, y serán comunicados previamente. Se aplicarán cargos por demoras.
                        <br>2. Cargos a Terceros Proveedores: Los Cargos mostrados al solicitar bienes o servicios pueden incluir los precios de venta al público cobrados por Terceros Proveedores y montos pagados o adeudados a Caddy Cab. Después de recibir los servicios o bienes, Caddy Cab facilitará el pago de los Cargos e impuestos en nombre del Tercero Proveedor o de Caddy Cab, según corresponda. Este pago se considerará realizado directamente por usted al Tercero Proveedor o a Caddy Cab.
                        <br>3. Facturación y Documentación Tributaria: La emisión de facturas, recibos, comprobantes fiscales y cualquier documentación tributaria es responsabilidad exclusiva de los Terceros Proveedores o, según corresponda, de Caddy Cab México S de RL del C.V. Caddy Cab puede asistir en el proceso de facturación según lo acordado, pero no asume responsabilidad por las obligaciones fiscales del Tercero Proveedor. Usted debe proporcionar la información fiscal pertinente.
                        <br>4. Cargos Definitivos y No Reembolsables: Los Cargos pagados por usted a un Tercero Proveedor son considerados definitivos y, por lo general, no son reembolsables, a menos que Caddy Cab o la regulación aplicable indique lo contrario. Estos Cargos buscan compensar plenamente al Tercero Proveedor por los servicios o bienes en relación con su uso de los Servicios.
                        <br>5. Acuerdos de Cargos Adicionales: Usted puede acordar Cargos adicionales con un Tercero Proveedor por servicios o bienes específicos. Caddy Cab podrá revisar solicitudes para modificar Cargos, presentadas a través de la Aplicación, para un servicio o bien en particular.
                        <br>6. Recibos: Después de cada Cargo, Caddy Cab pondrá a su disposición un recibo (sin valor fiscal) que podrá consultar en su Aplicación, correo electrónico u otro medio designado por Caddy Cab México S de RL del C.V.
                    </div><br>
                    <div class="m-2 lineup text-start"><b>Modificación de Cargos</b><br>
                        1. Establecimiento y Revisión de Cargos: Usted puede establecer, eliminar o revisar los Cargos para los servicios o bienes obtenidos mediante los Servicios. Debe pagar los Cargos aplicables, que pueden aumentar durante periodos de alta demanda en ciertas áreas. Se le informará sobre los Cargos aplicables.
                        <br>2. Ofertas y Descuentos: Caddy Cab puede ofrecer ofertas promocionales y descuentos a usuarios específicos, comunicándoselos directamente. Estas ofertas no se extienden a usuarios no notificados por Caddy Cab.
                        <br>3. Propinas: Caddy Cab no designa parte del Cargo como propina al Tercero Proveedor. Cualquier referencia sobre la propina es para indicar que es voluntaria. Puede optar por cancelar una solicitud antes de la llegada del Tercero Proveedor, con posibilidad de una tasa de cancelación.
                        <br>4. Gratificaciones Voluntarias: Usted puede proporcionar una gratificación adicional al Tercero Proveedor, pero no está obligado a hacerlo. Las gratificaciones son voluntarias y pueden ser proporcionadas después de recibir los bienes o servicios.
                    </div><br>
                    <div class="m-2 lineup text-start"><b>Tarifa de Reparación, Limpieza o Cargos por Objetos Olvidados</b><br>
                        1. Reparación o Limpieza: Usted será responsable de los costos de Reparación o Limpieza que excedan los daños normales y desgaste del uso de los Servicios. Caddy Cab facilitará el pago por estos costos en nombre del Tercero Proveedor, siendo no reembolsables.
                        <br>2. Cargos por Objetos Olvidados: Caddy Cab facilitará el pago por la devolución de objetos olvidados en nombre del Tercero Proveedor, siendo no reembolsables.
                    </div><br>
                    <div class="m-2 lineup text-start"><b>Métodos de Pago</b><br>
                        Registro de cuenta: El registro de la cuenta requiere al menos un método de pago válido.
                        <br>Pago en Efectivo:
                        <br>Si elige "Efectivo", es posible que necesite proporcionar un método de pago secundario.
                        <br>Si no hay suficiente efectivo para pagar los cargos, se cobrará el saldo restante al método secundario.
                        <br>Método de Pago Vencido o Inválido: Si el método principal está vencido o no es válido, Caddy Cab utilizará un método secundario disponible o cobrará en el próximo uso de los Servicios.
                        <br>Si el método seleccionado es rechazado, se le notificará para establecer un nuevo método de pago.
                        <br>Renuncias; Limitación de responsabilidad; Indemnidad
                        <br>Renuncias: Los servicios se proporcionan "tal cual" y "como disponibles". No se garantiza la fiabilidad, puntualidad, calidad, idoneidad o disponibilidad de los servicios.
                        <br>Caddy Cab no es responsable de la calidad, seguridad o habilidad de los Terceros Proveedores.
                    </div><br>
                    <div class="m-2 lineup text-start"><b>Limitación de Responsabilidad:</b><br>
                        Caddy Cab no será responsable de daños indirectos, incidentales o pérdidas. No se responsabiliza por daños derivados del uso de los servicios o de transacciones con terceros proveedores.
                        <br>Indemnidad: Usted acepta indemnizar a Caddy Cab por reclamaciones derivadas de su uso de los servicios, incumplimiento de las condiciones o violación de derechos de terceros.
                        <br>Legislación aplicable; Arbitraje. Estas Condiciones se rigen por la legislación de la Ciudad de México, a menos que las normas de órdenes públicos en su jurisdicción dispongan lo contrario.
                    </div><br>
                    <div class="m-2 lineup text-start"><b>Licencia y Restricciones</b><br>
                        Licencia: Sujeto al cumplimiento de las presentes Condiciones, Caddy Cab México S de RL del C.V le concede una licencia limitada, no exclusiva, no sublicenciable, revocable y no transferible para: Acceder y utilizar la Aplicación en su dispositivo personal, únicamente en relación con los Servicios. Acceder y utilizar cualquier contenido, información y material relacionado disponible a través de los Servicios, exclusivamente para su uso personal y no comercial. Caddy Cab México S de RL del C.V y sus licenciantes reservan todos los derechos no expresamente otorgados por estas Condiciones.
                        <br>Restricciones: Usted se compromete a no: Retirar cualquier nota de derechos de autor, marca registrada u otra nota de propiedad de los Servicios, reproducir, modificar, crear obras derivadas, distribuir, licenciar, arrendar, revender, transferir, exhibir públicamente, presentar públicamente, transmitir o explotar de otra manera los Servicios, excepto lo expresamente permitido por Caddy Cab México S de RL del C.V., descompilar, realizar ingeniería inversa o desmontar los Servicios, excepto según lo permita la ley aplicable, enlazar, reflejar o enmarcar cualquier parte de los Servicios.
                        <br>Causar o lanzar programas o scripts para extraer, indexar, analizar o realizar prospección de datos de los Servicios, o sobrecargar o bloquear indebidamente su operación o funcionalidad, realizar ingeniería inversa, lanzar programas o scripts, o acceder a los Servicios con el objetivo de crear un producto o servicio similar o competitivo con los ofrecidos por Caddy Cab México S de RL del C.V., intentar obtener un acceso no autorizado o dañar cualquier aspecto de los Servicios, sus sistemas o redes
                    </div><br>
                    <div class="m-2 lineup text-start"><b>Otras disposiciones</b><br>
                        Notificaciones: Caddy Cab puede notificar mediante la Aplicación, correo electrónico o comunicación escrita. Usted puede notificar a través de contacto@caddy.com.mx.
                        <br>Disposiciones Generales: No puede ceder estas Condiciones sin el consentimiento de Caddy Cab. Caddy Cab puede ceder estas condiciones a subsidiarias, afiliadas o en caso de adquisición. No hay empresa conjunta o relación de socios, empleo o agencia entre Usted, Caddy Cab y Terceros Proveedores. Caddy Cab puede denegar el acceso por incumplimiento.
                    </div><br>
                    <div class="m-2 lineup text-start"><b>Disposiciones adicionales:</b><br>
                        La nulidad de una disposición no afecta la validez de las demás. Estas condiciones constituyen el contrato completo entre las partes. Caddy Cab se reserva el derecho de compartir información con autoridades competentes. Estas Condiciones están sujetas a cambios y se recomienda revisarlo. Al proporcionar sus datos personales, usted acepta y consiente el tratamiento de los mismos de acuerdo con los términos y condiciones señalados en el presente. 21 febrero 2024
                    </div><br>
                </div>
            </div>
        </div>
    </div>

</template>
<script setup>
    const mnmap = require("ASSETS/images/bgmap")

</script>

<style scoped lang="scss">
</style>