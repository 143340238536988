<template>
    <div class="container-fluid m-0 p-0" id="home">
        <img :src="mnmap" alt="Imagen de fondo" style="position: fixed;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;opacity: 0.05;z-index: -1;">
        <div class="row d-flex mx-md-5 mx-2">
            <div class="col-12 mt-5">
                <div class="d-flex justify-content-center m-md-5 m-3 text-center">
                    <h3 class="m-2 lineup description-md"><b>La seguridad de nuestros clientes</b> es nuestra máxima prioridad. 
                    Implementamos rigurosas medidas de seguridad, desde la selección de conductores hasta el mantenimiento de vehículos, 
                    para garantizar viajes seguros en todo momento.
                    </h3>
                </div>
            </div>
        </div>
            <div class="row d-flex mx-lg-5 mx-1 px-md-5 px-1">
                <div class="col-12">
                    <Carousel :items-to-show="3" :wrap-around="true" :autoplay="4000">
                        <Slide v-for="(homesli, index) in galleryphone" :key="index">
                        <div class="m-md-3 m-1">
                        <img class="img-fluid imghover4" :src="homesli.img">
                        </div>
                        </Slide>

                        <template #addons>
                        </template>
                    </Carousel>
                </div>
            </div>
            <div class="row d-flex mx-md-5 mx-2">
                <div class="col-12">
                    <div class="d-flex justify-content-center m-md-5 m-3 text-center">
                        <h3 class="m-2 lineup description-md">Creemos en la transparencia y la honestidad. Nuestras tarifas son competitivas 
                        y se calculan de manera justa. Antes de confirmar tu viaje, podrás conocer la tarifa estimada, 
                        evitando sorpresas desagradables al final del trayecto.
                        </h3>
                    </div>
                </div>
            </div>
            <div class="row d-flex mx-md-5 mx-1">
                <div class="col-12">
                    <div class="d-flex m-md-4 m-2 text-start">
                        <h2 class="m-2 title-sm"><b>Cómo realizar viajes <br>con Caddy Cab</b> 
                        </h2>
                    </div>
                    <div class="d-flex align-items-center description-sm m-md-5 m-1 text-start ">
                        <img :src="logoblue" class="m-md-2 m-1" style="max-height: 70px;">
                        <h3 class="m-2"><b class="description-md">1. Pide un viaje</b><br>
                            Abre la app e ingresa tu destino en la casilla ¿A dónde vas? Una vez que confirmes que el punto de partida y el destino son correctos. 
                            Cuando se te asigne un conductor, verás su foto y los detalles del vehículo, y podrás hacer un seguimiento de su llegada en el mapa.
                        </h3>
                    </div>
                    <div class="d-flex align-items-center description-sm m-md-5 m-1 text-start ">
                        <img :src="logoblue" class="m-md-2 m-1" style="max-height: 70px;">
                        <h3 class="m-2"><b class="description-md">2. Viaja</b><br>
                            Verifica que los detalles del vehículo coincidan con lo que ves en la app antes de subirte al auto.
                            El conductor conoce tu destino y el camino para llegar de la manera más rápida, 
                            pero puedes indicarle que siga una ruta específica.
                        </h3>
                    </div>
                    <div class="d-flex align-items-center description-sm m-md-5 m-1 text-start ">
                        <img :src="logoblue" class="m-md-2 m-1" style="max-height: 70px;">
                        <h3 class="m-2"><b class="description-md">3. Baja del Vehículo</b><br>
                            El viaje se cobrará automáticamente a tu método de pago, por lo que podrás salir del vehículo apenas llegues a tu destino.
                            Recuerda calificar al conductor para ayudar a que la experiencia sea segura y agradable para todos.
                        </h3>
                    </div>
                </div>
            </div>
            <cc-apps></cc-apps>
    </div>

</template>
<script setup>
    import { ref } from 'vue'
    import { Carousel, Slide } from 'vue3-carousel'

    const galleryphone = ref([ 
        {img: ref(require("ASSETS/images/operation/img1"))}, 
        {img: ref(require("ASSETS/images/operation/img2"))}, 
        {img: ref(require("ASSETS/images/operation/img3"))}
    ])

    const mnmap = require("ASSETS/images/bgmap")

    const logoblue = require("ASSETS/images/home/caddyblue.webp")



</script>

<style scoped lang="scss">
</style>