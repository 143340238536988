<template >
    <footer class="bg-primary w-100 h-100" style="">
        <div class="row-fluid bg-white" style="height: 25px; clip-path: ellipse(50% 100% at 50% 0%); margin-top:; "></div>
        <div class="row border-white mx-md-5 mx-3">
            <div class="col-sm-6 col-12 text-sm-start text-center my-2">
                <img :src="caddymn" class="img-fluid m-3 imghover" style="min-height: 35px;"> 
                <br> 
                <img :src="fcicon" class="img-fluid m-2 imghover" style="height: 30px;">
                <img :src="xicon" class="img-fluid m-2 imghover" style="height: 30px;">
                <img :src="igicon" class="img-fluid m-2 imghover" style="height: 30px;">
                <img :src="likn" class="img-fluid m-2 imghover" style="height: 30px;">
                <br>
                <span class="text-white description-xs "  style="line-height: 1.6; letter-spacing: 1px; ">
                    <img :src="locationicon" class="img-fluid m-3 imghover" style="height: 20px;">Chihuahua
                </span>
            </div> 
            <div class="col-sm-6 col-12 d-flex justify-content-end flex-column text-sm-end text-center">
                <div class="description-md text-white imghover4">
                Más información
                </div>
                <div class="description-md text-white mb-2 imghover4">
                    <b><a class="text-white text-decoration-none" href="tel:+526146067566">614 606 7566</a></b>
                </div>
                <div class="mb-md-0 mb-3">
                    <img :src="store1" class="img-fluid my-2 mx-2 navhover" style="max-height: 30px;">
                    <a href="https://play.google.com/store/apps/details?id=mx.com.caddycab.cliente" Target="_blank"><img :src="store2" class="img-fluid my-2 navhover" style="max-height: 30px;"></a>
                </div>
                <div class="description-xs text-white mb-2">
                    <b><a class="text-white text-decoration-none imghover2 me-2" style="font-size: 10px;" @click="navigateToNotice">Aviso de privacidad</a></b>
                    <b><a class="text-white text-decoration-none imghover2" style="font-size: 10px;" @click="navigateToTerms">Términos y condiciones</a></b>
                </div>
            </div>
        </div>
            <div class="row-fluid bg-info" style="border-radius: 100% 100% 0 0 ;">
                <div class="col-12 text-center justify-content-center d-flex align-items-center flex-column my-sm-2 my-1">  
                    <a href="https://monocilindrero.com/" Target="_blank"><img :src="mono" style="max-height: 35px;" class="m-1 imghover"></a>
                    <a style=" letter-spacing: 2px; font-size: 12px;" target="_blank" href="https://monocilindrero.com/" class="text-decoration-none text-white imghover3 description-xs mb-1">
                        Desarrollado por <b>monocilindrero.com</b>
                    </a>
                </div> 
            </div>
        </footer>
</template>
<script setup>
    import { ref } from "vue";
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    const router = useRouter()

    const caddymn = require("ASSETS/images/CaddyLogo")
    const store1 = require("ASSETS/images/awhitestore") 
    const store2 = require("ASSETS/images/gblackstore") 

    const fcicon = require("ASSETS/icons/facebookicon")
    const igicon = require("ASSETS/icons/igicon")
    const xicon = require("ASSETS/icons/twitter")
    const likn = require("ASSETS/icons/in")

    const locationicon = require("ASSETS/icons/location")

    const mono = require("ASSETS/images/monologo")

    const navigateToNotice = () => {
        router.push('/aviso-de-privacidad');
        window.scrollTo(0, 0); 
    }
    const navigateToTerms = () => {
        router.push('/terminos');
        window.scrollTo(0, 0); 
    }

</script>
<style scoped lang="scss">

    .buttonshine:hover {
        animation-name: shine;
        animation-duration: 310ms;
        transform: scale(1.1);
        box-shadow: 6px 6px 6px #9a9a9a;
        background-color: #7C9895;

    }
    .img-mono {
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.1) rotate(360deg);
                
        }
    }
    .navhover:hover{
        transform: scale(1.2);
        cursor: pointer;

    } 

    @keyframes shine {
    0% {
        background: linear-gradient(
        30deg, 
        hsl(260, 85%, 95%) 0%, 
        #7C9895 25%
        );
    }

    25% {
        background: linear-gradient(
        30deg, 
        #7C9895 0%, 
        hsl(260, 85%, 95%) 25%, 
        #7C9895 50%);
    }

    50% {
        background: linear-gradient(
        30deg,
        #7C9895 0%, 
        hsl(260, 85%, 95%) 50%, 
        #7C9895 75%); 
    }

    75% {
        background: linear-gradient(
        30deg, 
        #7C9895 0%, 
        hsl(260, 85%, 95%) 75%, 
        #7C9895 100%); 
    }

    100% {
        background: linear-gradient(
        30deg, 
        #7C9895 0%, 
        hsl(260, 85%, 95%) 100%); 
        }
    }  
    
</style>