<template>
    <div class="container-fluid m-0 p-0" id="home">
        <img :src="mnmap" alt="Imagen de fondo" style="position: fixed;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;opacity: 0.05;z-index: -1;">
        <div class="row-fluid" >
            <div class="col-12 d-flex flex-center">
                <div class="d-flex justify-content-center title-sm mt-5 text-center">
                    <h2 class="mt-5 lineup title-sm">
                        Viaja seguro, a <b>donde quiera</b> que vayas.
                    </h2>
                </div>
            </div>
            <div class="row-fluid m-md-5 m-3">
                <div class="col-12">
                    <img class="img-fluid" :src="mainus">
                </div>
            </div>
            <div class="row-fluid">
            
                <div class="col-12">
                    <div class="d-flex justify-content-center description-md m-md-5 m-3 text-center">
                        <h3 class="m-2">
                            Bienvenido a <b>Caddy</b>, la opción líder en servicio de transporte en Chihuahua. 
                            Nos enorgullece ofrecer una experiencia confiable, segura y conveniente para nuestros clientes en toda la ciudad. 
                            Nuestro compromiso es brindar un servicio de calidad que supere tus expectativas y haga que tus viajes sean cómodos 
                            y sin complicaciones.
                        </h3>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-center m-lg-5 m-2">
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="p-xl-5 p-3 d-flex justify-content-center flex-column">
                        <img class="rounded-circle img-fluid rotateimg" :src="circle2">
                        <h3 class="text-center p-md-3 p-1 description-md text-primary">
                            <b>Flota Moderna y Variada</b>
                        </h3>
                        <h4 class="text-center px-lg-3 px-1 description-xs">
                            Contamos con una flota diversa de vehículos, desde automóviles compactos hasta 
                            vehículos más amplios para satisfacer tus necesidades específicas. 
                            Nuestra flota se mantiene en condiciones óptimas para garantizar tu seguridad y comodidad en cada viaje.
                        </h4>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="p-xl-5 p-3 d-flex justify-content-center flex-column">
                        <img class="rounded-circle img-fluid rotateimg" :src="circle1">
                        <h3 class="text-center p-md-3 p-1 description-md text-primary">
                            <b>Aplicación Intuitiva</b>
                        </h3>
                        <h4 class="text-center px-lg-3 px-1 description-xs">
                            Hacer que tus desplazamientos sean fáciles es nuestra prioridad. 
                            Utiliza nuestra aplicación fácil de usar para solicitar y gestionar 
                            tus viajes. Desde la reserva hasta el pago, todo está al alcance de tu mano.
                        </h4>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="p-xl-5 p-3 d-flex justify-content-center flex-column">
                        <img class="rounded-circle img-fluid rotateimg" :src="circle3">
                        <h3 class="text-center p-md-3 p-1 description-md text-primary">
                            <b>Conductores Profesionales</b>
                        </h3>
                        <h4 class="text-center px-lg-3 px-1 description-xs">
                            Nuestros conductores están cuidadosamente seleccionados y 
                            capacitados para ofrecer un servicio de alta calidad. Son 
                            profesionales amigables y comprometidos con tu satisfacción y seguridad en el camino.
                        </h4>
                    </div>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-12">
                    <div class="d-flex justify-content-center description-md m-md-5 m-3 text-center description-md">
                        <h3 class="m-2 ">
                            En <b>Caddy</b>, no solo ofrecemos un servicio transporte, sino una solución integral para tus 
                            necesidades de movilidad en Chihuahua. Ya sea que estés yendo al aeropuerto, a una reunión 
                            importante o simplemente explorando la ciudad, confía en nosotros para llevarte allí de manera 
                            segura y eficiente. ¡Descubre la diferencia con <b>Caddy</b> hoy mismo!
                        </h3>
                    </div>
                </div>
            </div>
            <cc-apps></cc-apps>
        </div>
    </div>

</template>
<script setup>
    const mnmap = require("ASSETS/images/bgmap")
    const circle1 = require("ASSETS/images/us/circle1")
    const circle2 = require("ASSETS/images/us/circle2")
    const circle3 = require("ASSETS/images/us/circle3")
    const mainus = require("ASSETS/images/us/usmain")



</script>

<style scoped lang="scss">

</style>
