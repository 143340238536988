<template>
    <div class="container-fluid m-0 p-0" id="home">
        <img :src="mnmap" alt="Imagen de fondo" style="position: fixed;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;opacity: 0.05;z-index: -1;">
        <div class="row-fluid">
            <div class="col-12 d-flex">
                <div class="d-flex title-sm mt-5 text-md-start text-center mx-5 text-white">
                    <div class="mt-5 lineup" style="text-shadow: #672683 1px 0 20px;">Aviso de Privacidad</div>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex justify-content-center description-xs m-md-3 m-1 text-start flex-column" >
                    <div class="m-2 lineup"><b>Aviso de privacidad Caddy Cab</b><br>
                        En este aviso de privacidad, se describe la forma en la que Caddy Cab México S de RL de C.V. 
                        Río Ebro 2020 Ribeeras del sacramento número 2020, C.P 31184, contacto@caddy.com.mx, teléfono 614 470 8296, 
                        recopila y usa los datos. Este aviso de privacidad aplica a todos los usuarios de Caddy Cab, aplicaciones, 
                        sitios web y otros servicios de Caddy Cab. Este aviso se aplica específicamente a los siguientes usuarios: 
                        Pasajeros: personas que solicitan, utilizan la aplicación o el servicio para solicitar y utilizar el transporte 
                        ofrecido o servicios relacionados a través de su cuenta de Caddy Cab. Conductores: personas u operadores del vehículo 
                        que brindan el servicio de transporte a los pasajeros de forma individual a través de su cuenta de Caddy Cab o mediante 
                        empresas de transporte asociadas. Todas las personas a las que se les aplica este aviso se denominará "usuarios" 
                        <u>Para registrarse en Caddy Cab, los usuarios deben tener al menos 18 años o la mayoría de edad legal en su jurisdicción. Este requisito se establece para cumplir con las leyes y regulaciones relacionadas con la privacidad y el consentimiento de los usuarios.</u>
                        
                    </div><br>
                    <!------------------------------------------------------------------------------>
                    <div class="m-2 lineup"><b>Recopilación de datos y usos</b><br>
                        Información de Registro (Nombre completo, dirección de correo electrónico, número de teléfono, nombre de inicio de sesión y contraseña, dirección, foto de perfil, información de pago o bancaria)
                        <br>En caso del conductor, adicional también se incluye la información del seguro o del vehículo, la información de contactos de emergencia, y certificados de salud o aptitud física para prestar servicios a través de las Apps de Caddy Cab.
                        <br>
                        <br>
                        Datos que proporcionan los usuarios. Incluyen
                        <ul>
                            <li>
                            Información de la cuenta: Recopilamos datos cuando los usuarios crean o actualizan sus cuentas de Caddy Cab o hacen pedidos a través de las funciones de pago como invitado. Esto incluye nombre y apellidos, correo, número de teléfono, nombre de inicio de sesión y contraseña, dirección, foto de perfil, información de pago o bancaria (incluida la información de verificación de pago relacionada), configuración del usuario e información del programa de fidelidad para Socios de Caddy Cab.
                            </li>
                            <br>
                            <li>
                            En el caso de conductores y repartidores, también se incluye la información del seguro o del vehículo, la información de contactos de emergencia, y certificados de salud o aptitud física para prestar servicios a través de las Apps de Caddy Cab.
                            </li>
                            <li>
                            Información sobre la comprobación de antecedentes (conductores y repartidores): Incluye información enviada durante el proceso de solicitud del conductor o repartidor, como el historial del conductor o los antecedentes penales (si la ley lo permite), el estado de la licencia, alias conocidos, direcciones anteriores y requisitos que cumple para trabajar. Los proveedores de servicios de Caddy Cab pueden recopilar esta información.
                            </li>
                            <li>
                            Fotografías y documentos de verificación de identidad: Esto incluye identificación emitida por el gobierno, como permiso de conducir o pasaportes (que pueden contener fotos y números de identificación, fechas de nacimiento y género) y fotos enviadas por los usuarios, como selfies y fotos de perfil.
                            </li>
                            <li>
                            Datos demográficos: Recopilamos datos demográficos como la fecha de nacimiento o la edad, el género o la ocupación cuando es necesario para habilitar ciertas funciones o proporcionar acceso a productos o servicios con restricción de edad. Por ejemplo, recopilamos la fecha de nacimiento o la edad de los usuarios para verificar si cumplen los requisitos para usar Caddy Cab Cash o Caddy Cab Money, o cuando compran alcohol o productos con cannabis. También recopilamos o deducimos (mediante el nombre) información de género para permitir que las mujeres o los usuarios no binarios indiquen su preferencia para proporcionar o recibir servicios de usuarios mujeres o no binarios.
                            </li>
                            <br>
                            <li>
                            También recopilamos datos demográficos, como el grupo de edad y la composición del hogar a través de encuestas de usuarios.
                            </li>
                            <li>
                            Contenido de usuarios: Recopilamos datos (como registros de chat y grabaciones de llamadas) cuando los usuarios se ponen en contacto con el servicio de asistencia al usuario de Caddy Cab, proporcionamos valoraciones o comentarios a usuarios, restaurantes o establecimientos, usamos funciones que permiten a los usuarios subir contenido o enviar grabaciones (como grabaciones de audio en la app o grabaciones de cámaras del panel), o cuando se ponen en contacto con Caddy Cab.                            
                            </li>
                            <br>
                            <li>
                            Consulte aquí (pasajeros), aquí (conductores) y aquí (repartidores) para obtener más información sobre cómo se determinan y utilizan las valoraciones proporcionadas por otros usuarios.
                            </li>
                            <li>
                            Información del viaje: Recopilamos información del itinerario de viaje, como las horas y las fechas de los próximos vuelos, los alojamientos o alquileres de vehículos, de los usuarios de nuestra función Caddy Cab. Recopilamos dicha información cuando los usuarios introducen manualmente su información en su itinerario de Caddy Cab, o si los usuarios han autorizado el acceso a sus cuentas de correo a partir de confirmaciones por correo relacionadas con viajes. Si se autoriza, Caddy Cab solo accederá a las cuentas de correo de los usuarios para recopilar información sobre el itinerario del viaje a fin de activar la función Caddy Cab, y cumplirá las políticas aplicables del proveedor de correo, incluida la Política de datos de usuario. Consulte esta página para obtener información sobre cómo los usuarios pueden eliminar el acceso de Caddy Cab a sus cuentas de correo a través de la app Caddy Cab o la configuración de su proveedor de servicios de correo.
                            </li>
                        </ul>
                        <br>
                        Datos que se generan durante el uso de nuestros servicios. Incluyen: 
                        <ul>
                            <li>
                            Datos de ubicación (conductores y repartidores): Recopilamos datos de ubicación precisos o aproximados de los dispositivos móviles de los conductores y repartidores cuando la app Caddy Cab se ejecuta en primer plano (app abierta y en pantalla) o en segundo plano (app abierta pero no en pantalla).
                            </li>
                            <li>
                            Datos de ubicación (pasajeros y destinatarios de pedidos): Recopilamos información de ubicación precisa o aproximada de los dispositivos móviles de los pasajeros y los destinatarios de pedidos si nos permiten hacerlo a través de la configuración de sus dispositivos.                            
                            </li>
                            <br>
                            <li>
                            Caddy Cab recopila estos datos desde el momento en el que se solicita un viaje o pedido hasta que finaliza, y siempre que la app se ejecute en primer plano (app abierta y en pantalla). Consulte la sección "Elección y transparencia" para obtener información sobre cómo los pasajeros y los destinatarios de pedidos pueden activar la recopilación de datos de ubicación precisos.
                            </li>
                            <br>
                            <li>
                            Los pasajeros y los destinatarios de pedidos pueden usar las apps de Caddy Cab sin permitir la recopilación de datos de ubicación de sus dispositivos móviles. Sin embargo, esto puede afectar a determinadas funciones de las apps de Caddy Cab. Por ejemplo, si un pasajero no ha activado los datos de ubicación precisos, tendrá que introducir su dirección de recogida manualmente.                            
                            </li>
                            <li>
                            Además, los datos de ubicación precisos recopilados del dispositivo de un conductor durante un viaje se vinculan a la cuenta del pasajero, incluso si el pasajero no ha activado la recopilación de datos de ubicación precisos desde su dispositivo. Estos datos se utilizan para fines como asistencia al usuario, detección de fraudes, seguros, litigios y creación de recibos.                            
                            </li>
                            <li>
                            Información de las transacciones: Recopilamos datos de las transacciones relacionadas con el uso de nuestros servicios, entre los que se incluyen la información sobre el tipo de servicios solicitados o proporcionados, detalles del viaje o del pedido (como fecha y la hora, direcciones de recogida y destino solicitadas, distancia recorrida y artículos pedidos) e información sobre las transacciones de pago (como el nombre y la ubicación del restaurante o establecimiento, el importe que se cobró y la forma de pago). También asociamos el nombre del usuario al de cualquier persona que use su código promocional.                            
                            </li>
                            <li>
                            Datos de uso: Recopilamos datos sobre la forma en la que los usuarios interactúan con nuestros servicios. Entre ellos se incluyen las fechas y horas de acceso, las funciones de la app o las páginas visitadas, el tipo de navegador, los bloqueos de la app y otra actividad del sistema.                            
                            </li>
                            <li>
                            Datos de los dispositivos: Recopilamos datos sobre los dispositivos que se utilizan para acceder a nuestros servicios, como el modelo de hardware, la dirección IP del dispositivo, el sistema operativo y su versión, el software, los idiomas preferidos, los identificadores de dispositivos, los identificadores de publicidad, los datos del movimiento del dispositivo y los datos de la red móvil.                            
                            </li>
                            <li>
                            Datos de comunicaciones: Recopilamos datos sobre las comunicaciones entre usuarios que se establecen a través de las Apps de Caddy Cab. Esto incluye el tipo de comunicación (teléfono, mensaje de texto o mensaje a través de la app), la fecha y la hora, y el contenido (incluidas las grabaciones de llamadas telefónicas solo cuando se notifica a los usuarios de la grabación con antelación).                            
                            </li>
                        </ul>
                    <br>
                    </div>
                    <!------------------------------------------------------------------------------>
                    <div class="m-2 lineup"><b>Eliminación del acceso:</b><br>
                        Los usuarios pueden eliminar el acceso de Caddy Cab a sus cuentas de correo a través del correo contacto@caddy.com.mx o en la aplicación Caddy Cab, la 
                        configuración de su proveedor de servicios de correo. Enlace a la Política de datos de usuario Caddy Cab recopila estos datos desde el momento en el que se solicita 
                        un viaje hasta que finaliza, y siempre que la app se ejecute en primer plano (app abierta y en pantalla). Datos de ubicación (conductores): Recopilamos datos de ubicación 
                        precisos o aproximados de los dispositivos móviles de los conductores cuando la app Caddy Cab se ejecuta en primer plano (app abierta y en pantalla) o en segundo plano 
                        (app abierta pero no en pantalla). Datos de ubicación (pasajeros): Recopilamos información de ubicación precisa o aproximada de los dispositivos móviles de los pasajeros 
                        si nos permiten hacerlo a través de la configuración de sus dispositivos.Además, los datos de ubicación precisos recopilados del dispositivo de un conductor durante un viaje se vinculan a la cuenta del pasajero, 
                        incluso si el pasajero no ha activado la recopilación de datos de ubicación precisos desde su dispositivo. Estos datos se utilizan para fines como asistencia al usuario, detección de fraudes, seguros, litigios y creación de recibos. 
                        Información del Dispositivo (Tipo de dispositivo identificadores únicos del dispositivo para acceder a nuestros servicios, modelo de hardware, la dirección IP del dispositivo, el sistema operativo y su versión, el software, 
                        los idiomas preferidos, los identificadores de dispositivos, los identificadores de publicidad, los datos del movimiento del dispositivo y los datos de la red móvil).
                    </div><br>
                    <!------------------------------------------------------------------------------>
                    <div class="m-2 lineup"><b>Uso de la Información:</b><br>
                        Utilizamos la información recopilada para (Brindar el servicio de taxi solicitado, mejorar la calidad de nuestro servicio, enviar notificaciones y actualizaciones relacionadas con el servicio, facturación y procesamiento de pagos (si aplica)).    
                        <br>
                        Para brindar un mejor servicio nos enfocaremos en los siguientes aspectos para garantizar una mejor experiencia;
                        <ul>
                            <li>
                                Mejorar la seguridad de nuestros usuarios y servicios
                            </li>
                            <li>
                                Ofrecer asistencia al cliente 
                            </li>
                            <li>
                                Investigar y desarrollar funciones y servicios 
                            </li>
                            <li>
                                Permitir la comunicación entre los usuarios 
                            </li>
                            <li>
                                Marketing y publicidad
                            </li>
                            <li>
                                Enviar comunicaciones que no sean de marketing a los usuarios
                            </li>
                            <li>
                                Cumplir requisitos legales
                            </li>
                        </ul>                
                    </div><br>
                    <!------------------------------------------------------------------------------>
                    <div class="m-2 lineup"><b>Compartir Información:</b><br>
                        No compartimos información personal con terceros sin su consentimiento, excepto cuando sea necesario para brindar el servicio o cumplir con requisitos legales.                    
                    </div><br>
                    <!------------------------------------------------------------------------------>
                    <div class="m-2 lineup"><b>Seguridad de la Información:</b><br>
                        Implementamos medidas de seguridad para proteger la información contra acceso no autorizado, pérdida o alteración.
                        Seguridad y protección contra fraudes. Utilizamos datos para mantener la protección, seguridad e integridad de nuestros servicios y usuarios. Incluyen:
                        Verificar las cuentas de los usuarios, su identidad o el cumplimiento de los requisitos de seguridad. También requerimos la verificación de la identidad del usuario o la edad para usar formas de pago como efectivo, procesamos imágenes de perfil de usuario, fotos y números de identificación emitidos por el gobierno u otras fotografías enviadas por usuarios para realizar esta verificación, incluso en algunas regiones mediante el uso de tecnología de reconocimiento facial. También utilizamos dicha tecnología para evitar el uso fraudulento de las fotos de identificación o para evitar que los usuarios creen varias cuentas.
                        También utilizamos tecnología de reconocimiento facial para evitar el uso fraudulento de las cuentas de Caddy Cab por parte de personas que no sean las propietarias de las cuentas. 
                        Usar la cuenta, el dispositivo, la ubicación, el uso, la transacción, el proveedor de servicios inalámbricos y otros datos, incluidas las comunicaciones entre los usuarios y los metadatos, para prevenir, detectar y combatir el fraude, incluso por parte de usuarios invitados. 
                        Utilizar los incidentes notificados, las valoraciones de los usuarios y otros comentarios para fomentar un uso seguro de la plataforma Caddy Cab y el cumplimiento de nuestras condiciones, y como motivo para desactivar a los usuarios con valoraciones bajas.
                        Usar datos del conductor (como información de viajes anteriores e índices de incidentes notificados) y datos del pasajero (como información de viajes anteriores, índices de cancelación e incidentes notificados, información de la cuenta, información de valoraciones y ubicación actual de recogida y de destino) para predecir y ayudar a evitar asignaciones de usuarios que pueden dar lugar a un mayor riesgo de conflicto*. También evitamos asignaciones de un usuario que le haya dado una baja valoración (p. ej., de una estrella) a otro. Utilizar la ubicación, el número de teléfono, el nombre de usuario, los detalles del vehículo y otra información relevante para proporcionar asistencia en tiempo real por parte de expertos en seguridad durante los viajes.
                        Las actividades de prevención y detección de fraudes y conducción insegura descritas anteriormente se pueden considerar de creación de perfiles según las leyes aplicables y pueden dar lugar a la desactivación de los usuarios (por lo general, solo después de una revisión humana). Para obtener información sobre cómo objetar las actividades anteriores, consulte “Elección y transparencia” a continuación.
                        Caddy Cab lleva a cabo las actividades anteriores porque son necesarias para cumplir las condiciones de nuestros acuerdos con los usuarios o para proteger los intereses legítimos de Caddy Cab, nuestros usuarios y el público en general.
                    </div><br>
                    <!------------------------------------------------------------------------------>
                    <div class="m-2 lineup"><b>Acceso y Control:</b><br>
                        Los usuarios pueden acceder, corregir o eliminar su información personal a través de la configuración de la aplicación.
                        Recopilamos información sobre cómo los usuarios interactúan con nuestros servicios. Esta información incluye:
                        <ul>
                            <li>
                            Fechas y horas de acceso: Registramos cuándo los usuarios inician y finalizan sus sesiones en la aplicación.
                            </li>
                            <li>
                            Funciones de la aplicación o páginas visitadas: Seguimos qué funciones de la aplicación utilizan los usuarios y qué páginas web o contenido visitan dentro de la aplicación.
                            </li>
                            <li>
                            Tipo de navegador: Identificamos el tipo de navegador web que utilizan los usuarios para acceder a nuestros servicios.
                            </li>
                            <li>
                            Bloqueos de la aplicación: Registramos las ocasiones en que la aplicación se bloquea o presenta errores.
                            </li>
                            <li>
                            Otra actividad del sistema: Recopilamos información sobre otras acciones que realizan los usuarios dentro de la aplicación.
                            </li>
                        </ul>
                        Cómo Caddy Cab utiliza los datos que recopila
                        <br>1. Proporcionar nuestros servicios:
                        <br>Caddy Cab utiliza los datos que recopila para:
                        <ul>
                            <li>
                            Crear o actualizar cuentas: Registrar y gestionar las cuentas de los usuarios.
                            </li>
                            <li>
                            Permitir el transporte o funciones:
                                <ul>
                                    <li>
                                    Utilizar los datos de ubicación: Buscar a los pasajeros en las recogidas, calcular las horas estimadas de llegada y hacer un seguimiento del progreso de los viajes.
                                    </li>
                                    <li>
                                    Habilitar funciones que impliquen compartir datos: Compartir el nombre del conductor y la información del vehículo con los pasajeros para facilitar las recogidas, o funciones que permitan compartir la hora estimada de llegada y la división de precios.
                                    </li>
                                </ul>
                                Caddy Cab utiliza una combinación de datos personales y no personales para asignar conductores a los usuarios que solicitan los servicios.
                                <br>Datos personales:
                            </li>
                            <li>
                                Ubicación: Caddy Cab utiliza la ubicación del usuario para encontrar conductores.
                            </li>
                            <li>
                                Proximidad a otros usuarios: Se tiene en cuenta la proximidad a otros usuarios para optimizar la eficiencia de los viajes.
                            </li>
                            <li>
                                Configuración o preferencias del usuario: Se consideran las preferencias del usuario, como los destinos favoritos o el tipo de vehículo solicitado.
                            </li>
                                <br>Datos no personales:
                            <li>
                                Tipo de vehículo solicitado: Caddy Cab asigna un conductor con el vehículo adecuado para el tipo de servicio solicitado (por ejemplo, un vehículo con capacidad para 4 pasajeros.                            
                            </li>
                        </ul>
                        Soporte al cliente. Caddy Cab utiliza la información que recopilamos (que puede incluir grabaciones de llamadas, registros de chats, grabaciones de audio en la app y grabaciones de la dashcam) para proporcionar asistencia al usuario, lo que incluye investigar y resolver las preocupaciones de los usuarios y supervisar y mejorar los procesos de asistencia al usuario y nuestras respuestas.
                        Caddy Cab lleva a cabo las actividades anteriores porque son necesarias para cumplir las condiciones de nuestros acuerdos con los usuarios o para los intereses legítimos de Caddy Cab de supervisar y mejorar sus servicios de asistencia al usuario.
                        <br>
                        Investigación y desarrollo. Utilizamos los datos para análisis, aprendizaje automático, desarrollo de productos, investigación y pruebas. Esto nos ayuda a hacer que nuestros servicios sean más cómodos y fáciles de utilizar, mejorar la seguridad de nuestros servicios y desarrollar nuevos servicios y funciones.<br>
                        Facilitar las comunicaciones entre los usuarios. Por ejemplo, un conductor puede enviar un mensaje o llamar a un pasajero para confirmar la ubicación de recogida, un pasajero puede llamar a un conductor para recuperar un objeto perdido.<br>
                        Marketing y publicidad. Caddy Cab utiliza datos (aparte de los de los usuarios invitados) para comercializar sus servicios y los de los socios de Caddy Cab.<br>
                        Utilizamos específicamente la cuenta, la ubicación aproximada, los datos del dispositivo y de uso, así como el historial de viajes para ofrecer anuncios y comunicaciones de marketing personalizadas en función de la ubicación, los intereses y las características que los usuarios han observado o deducido. Esto incluye el uso de estos datos para lo siguiente: Enviar correos, mensajes de texto, notificaciones, mensajes en la app u otras comunicaciones de marketing o publicidad de productos, servicios, funciones, ofertas, promociones, sorteos, noticias y eventos de Caddy Cab. Por ejemplo, podemos enviar notificaciones para sugerir destinos o establecimientos favoritos de un usuario, o mensajes en la app que ofrezcan descuentos o promociones de productos o establecimientos similares que el usuario ha usado anteriormente.<br>
                        Mostrar publicidad de Caddy Cab en sitios web o aplicaciones de terceros.<br>
                        Mostrar publicidad de terceros en las Apps de Caddy Cab o en relación con nuestros servicios. También medimos la eficacia de los anuncios de Caddy Cab y de los anuncios de terceros que se muestran en las Apps de Caddy Cab o en relación con nuestros servicios.<br>
                    </div><br>
                    <div class="m-2 lineup">
                        <b>
                        Comunicaciones de otro tipo.
                        </b><br>
                        Caddy Cab puede utilizar los datos que recopila para enviar encuestas y otro tipo de comunicaciones cuyo objetivo no sea promocionar los servicios o productos de Caddy Cab o de sus socios. También podemos enviar comunicaciones relacionadas con elecciones, votaciones, referéndum y otros procesos políticos relacionados con nuestros servicios.
                    </div><br>
                    <div class="m-2 lineup">
                        <b>
                        Cumplir los procedimientos y requisitos legales. 
                        </b><br>
                        Utilizamos los datos que recopilamos para investigar o abordar reclamaciones o disputas relacionadas con el uso de los servicios de Caddy Cab, para cumplir los requisitos de las leyes, normativas, acuerdos o licencias de funcionamiento aplicables, o de conformidad con un proceso legal o una solicitud, incluso por parte de autoridades judiciales. 
                    </div><br>
                    <div class="m-2 lineup">
                        <b>
                        Cookies y tecnologías de terceros
                        </b><br>
                        Caddy Cab utiliza cookies y otras tecnologías de identificación en sus apps, sitios web, emails y publicidad en línea con los fines descritos en este aviso. (Las cookies son pequeños archivos de texto que los sitios web, las aplicaciones, los medios de comunicación online y los anuncios almacenan en navegadores o dispositivos. Caddy Cab utiliza cookies y tecnologías similares con los siguientes objetivos: Autenticar a los usuarios, recordar las preferencias y la configuración de los usuarios, determinar la popularidad del contenido, calcular y medir la efectividad de las campañas publicitarias, analizar el tráfico y las tendencias de los sitios web, y comprender de forma general los comportamientos en línea y los intereses de las personas que interactúan con nuestros servicios
                    </div><br>
                    <div class="m-2 lineup">
                        <b>
                        Uso de Cifrado
                        </b><br>
                        Toda información compartida entre cliente y aplicación es encriptada para la seguridad del cliente, no compartimos información con terceros, el usuario está en su propio derecho de eliminar su cuenta, inmediatamente al ser eliminada la cuenta, damos de baja toda información perteneciente al usuario.
                        <br>Ciframos la comunicación con protocolos TLS/SSL v1.3 para mayor seguridad en la emisión de la comunicación entre cliente - servidor.
                    </div><br>
                    <div class="m-2 lineup">
                        <b>
                        Intercambio y divulgación de datos
                        </b><br>
                        Algunos de los servicios y funciones de Caddy Cab requieren que compartamos datos con otros usuarios, a solicitud de los usuarios o con su consentimiento. Además, es posible que compartamos estos datos con nuestros socios, subsidiarias y filiales, o bien por razones legales o en caso de litigio.
                    </div><br>
                    <div class="m-2 lineup">
                        <b>
                        Compartir datos por motivos legales o en caso de litigio
                        </b><br>
                        Caddy Cab puede compartir datos de los usuarios en las siguientes situaciones, si lo exige la ley, una normativa, un acuerdo de colaboración, un proceso legal o una solicitud oficial, para cumplir con una póliza de seguro, por razones de seguridad o similares.
                        <br>Esto incluye compartir datos con autoridades judiciales, profesionales de la salud pública, otras autoridades del gobierno, aeropuertos (si es necesario para ofrecer servicios en el aeropuerto, compañías de seguros.
                        <br>En caso de una disputa relacionada con el uso de la tarjeta de crédito de otra persona, Caddy Cab puede estar obligado por ley a compartir los datos del usuario con el titular de la tarjeta, incluida la información sobre el viaje.
                    </div><br>
                    <div class="m-2 lineup">
                        <b>
                        Eliminación de cuenta
                        </b><br>
                        Los usuarios pueden solicitar la eliminación de su cuenta a través de las aplicaciones, el sitio web de Caddy Cab o en el correo electrónico Caddycabmexico@gmail.com. Tras la solicitud, eliminaremos la cuenta y los datos del usuario, excepto si son necesarios por motivos de protección, seguridad, prevención de fraudes o cumplimiento de requisitos legales.
                        <br>Conservación de datos
                        <br>Caddy Cab conserva los datos del usuario durante el tiempo necesario para los fines descritos en la política de privacidad. Los plazos de eliminación varían según el tipo de datos, la categoría de usuario a la que se relacionan y los fines para los que se recopilaron.
                        <ul>
                            <li>
                                Conductores: Algunos datos se conservan durante el tiempo necesario para posibles reclamaciones fiscales, de seguros o litigios.
                            </li>
                            <li>
                                Pasajeros: Los datos se eliminan en un plazo de 90 días desde la solicitud de eliminación, a menos que sea necesario conservarlos por los motivos mencionados anteriormente.
                            </li>
                        </ul>
                        Los pasajeros pueden permitir que Caddy Cab comparta datos de ubicación en tiempo real desde sus dispositivos móviles con los servicios de policía, bomberos y ambulancias. Entre esos datos, se incluyen la ubicación actual aproximada en el momento de la llamada al servicio de emergencias, la marca, el modelo, el color y la matrícula del vehículo, el nombre y número de teléfono del pasajero, las ubicaciones de recogida y destino, y el nombre del conductor. Caddy Cab ofrece a los usuarios notificaciones sobre el estado de los viajes y actualizaciones relacionadas con la actividad en su cuenta. Estas notificaciones son una parte necesaria del uso de la app Caddy Cab y no se pueden desactivar.
                    </div><br>
                    <div class="m-2 lineup">
                        <b>
                        Permisos de los dispositivos
                        </b><br>
                        Los usuarios deben verificar la configuración disponible en sus dispositivos o consultar con su proveedor.La mayoría de las plataformas de dispositivos móviles (iOS, Android, etc.) han definido ciertos tipos de datos de dispositivos a los que las apps no pueden acceder sin el permiso del propietario del dispositivo.                     
                        </div><br>
                    <div class="m-2 lineup">
                        <b>
                        Páginas de valoraciones de la app
                        </b><br>
                        Al final de cada viaje, los conductores y pasajeros pueden valorarse mutuamente con una puntuación de 1 a 5 estrellas. La media de esas valoraciones se asocia a la cuenta del usuario y se muestra al resto de usuarios a los que proporcionan servicios o de los que los reciben. Nuestra función Explora tus datos permite a los usuarios ver un resumen online de la información sobre su cuenta, como el número de viajes, la valoración, el estado de las recompensas y el tiempo que llevan siendo usuarios de Caddy Cab.
                    </div><br>
                    <div class="m-2 lineup">
                        <b>
                        Información legal
                        </b><br>
                        A. Responsables del tratamiento y de la protección de datos
                        <br>Caddy Cab México S de RL del C.V, es el responsable del tratamiento de los datos procesados en relación con el uso de los servicios de Caddy Cab.
                    </div><br>
                    <div class="m-2 lineup">
                        <b>
                        DERECHOS ARCO
                        </b><br>
                        Usted tiene derecho de Acceder, Rectificar, Cancelar u Oponerse al tratamiento de sus datos personales (derechos ARCO). Para ejercer estos derechos, así como para conocer los procedimientos y requisitos, se puede poner en contacto con nuestro Departamento de Privacidad a través de contacto@caddy.com.mx o 614 470 8296.                    
                        </div><br>
                    <div class="m-2 lineup">
                        <b>
                        Actualizaciones de este Aviso de privacidad
                        </b><br>
                        Si hacemos cambios significativos, se lo notificaremos a través de las apps de Caddy Cab o por otro medio como, por ejemplo, email. Le recomendamos que revise de forma periódica este aviso para obtener la información más actualizada sobre nuestras prácticas de privacidad. Si hace uso de nuestros servicios tras una actualización, consideraremos que acepta la política actualizada. Al proporcionar sus datos personales, usted acepta y consiente el tratamiento de los mismos de acuerdo con los términos y condiciones señalados en el presente aviso de privacidad. 21 febrero 2024.                    
                        </div><br>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    const mnmap = require("ASSETS/images/bgmap")

</script>

<style scoped lang="scss">
</style>