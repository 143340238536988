<template>
  <component :is="layout || 'section'">
      <router-view/>
  </component>
</template>
<script setup>
    // Vue imports
    import { ref, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects

    // Computeds
    const layout = computed(() => {
        return router.currentRoute.value.meta.layout
    })

    // Variables

    // Methods
    
</script>

<style lang="scss">
  #app {
      font-family: 'Playfair Display';
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: left;
      color: #2c3e50;
  }
  
  // Variable overrides  // Originals
  $primary:#672683;      // #0D6EFD;
  $secondary: #CCDE91;    // #6C757D;
  $success: #28889c;      // #198754;
  $info: #b492c2;         // #0DCAF0;
  $warning: #D29751;      // #FFC107;
  $danger: #DC3545;       // #DC3545;
  $light: #ffffff;        // #F8F9FA;
  $dark: #5F6368;         // #212529;
  

  // Then import Bootstrap
  @import "../node_modules/bootstrap/scss/bootstrap";


</style>
