<template>
    <div class="container-fluid m-0 p-0" id="home">
        <img :src="mnmap" alt="Imagen de fondo" style="position: fixed;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;opacity: 0.05;z-index: -1;">
    <div class="row-fluid">
        <!--prueba-->
        <div class="col-12 d-flex flex-center">
            <div class="position-relative d-flex flex-center">
                <img :src="imgbg" class="img-fluid col-12 imgbg" alt="Caddy Cab">
                <div class="col-xl-5 col-lg-7 col-md-9 col-11 rounded-4 position-absolute top-50 start-0 translate-middle-y mx-md-5 mx-4 img-fluid imgmn h-50 mt-4
                 mt-sm-0" 
                :style="{ backgroundImage: `url(${require('@/assets/images/home/bgleft.webp')})`, backgroundSize: 'cover', backgroundPosition: 'center'}">
                    <div class="row-fluid rounded-top-4 bg-light w-100 h-25 d-flex justify-content-between">
                        <img :src="logopurple" class="m-lg-3 m-md-2 m-1 img-fluid h-75 imghover3" style="">
                        <img :src="car" class="mx-2 img-fluid h-100 car_left" style="">
                    </div>
                    <h1 class="description-md mx-lg-5 mx-3 my-lg-2 my-sm-1 text-white pt-lg-2 text1">
                        Bienvenidos a 
                        <b>Caddy Cab</b> 
                        México
                    </h1>
                    <div class="description-xs mx-lg-5 mx-3 my-md-2 my-sm-1 text-white text2">
                        Una solución de transporte privado por
                        aplicativo móvil disponible en Chihuahua
                    </div>
                    <div class="row d-flex align-items-center h-25">
                        <div class="col-md-6 col-5 d-flex justify-content-start">
                            <button type="button" class="btn btn-primary mx-lg-5 mx-2 imghover3 w-100">
                                <div class="text-white text2">
                                    Comienza a hoy
                                </div>
                            </button>
                        </div>
                        <div class="col-md-6 col-7 d-flex justify-content-end flex-row">
                            <a href="https://play.google.com/store/apps/details?id=mx.com.caddycab.cliente" Target="_blank"><img :src="store1" class="img-fluid my-2 imghover3 imgapp" style="max-height: 35px;"></a>
                            <a href="https://play.google.com/store/apps/details?id=mx.com.caddycab.cliente" Target="_blank"><img :src="store2" class="img-fluid my-2 mx-md-3 mx-1 imghover3 imgapp" style="max-height: 35px;"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-12 d-flex flex-center">
            <h1 class="col-4 d-flex flex-center-center title-sm text-primary imghover2 lineup chihuahua">
                <b>Caddy Cab <br>
                Chihuahua</b>
            </h1>
            <img :src="imgbg2" class="img-fluid col-8 imgbg">
        </div>
        <div class="d-flex justify-content-end">
            <div class="col-10 bg-primary" style="height: 15px; border-radius: 10px 0 0 10px;"></div>
        </div>
        <div class="col-12">
            <h2 class="text-center title-sm m-md-5 m-3 lineup">
                <b>Viaja seguro, a donde quiera que vayas.</b>
            </h2>
        </div>
        <div class="row m-0">
            <div class="col-12">
                <Carousel :items-to-show="2" :wrap-around="true" :autoplay="2000">
                    <Slide v-for="(homesli, index) in homeslider" :key="index">
                    <div class="mx-md-5 mx-2 d-flex align-items-start flex-column">
                    <img class="img-fluid imghover4" :src="homesli.img">
                        <div class="text-primary description-xs my-3">{{ homesli.text }}
                        </div>
                    </div>
                    </Slide>

                    <template #addons>
                    <Navigation />
                    </template>
                </Carousel>
            </div>
        </div>
        <div class="row m-0 bg-primary">
            <div class="col-12">
                <h2 class="description-sm mx-md-3 mt-5 px-5 text-white">
                <b>Descarga Nuestras Apps</b> </h2>
            </div>
            <div class="row d-flex px-lg-5 px-3 pb-4">
            <div class="col-md-6">
                <div class="bg-white mx-lg-4 mx-2 my-3 d-flex align-items-center imgshadow text-center" style="border-radius: 15px;">
                    <img :src="logopurple" class="m-md-4 m-2 img-fluid col-3" style="">
                    <a class="description-xs col-md-5 col-6 text-primary" href="https://play.google.com/store/apps/details?id=mx.com.caddycab.cliente" Target="_blank"> 
                    Descarga <b>Caddy</b> Usuario</a>
                    <img :src="arrow" class="mx-3 img-fluid col-1" style="">
                </div>
            </div>
            <div class="col-md-6">
                <div class="bg-white mx-lg-4 mx-2 my-3 d-flex align-items-center imgshadow text-center" style="border-radius: 15px;">
                    <img :src="logoblue" class="m-md-4 m-2 img-fluid col-3" style="">
                    <a class="description-xs col-md-5 col-6 text-success" href="https://play.google.com/store/apps/details?id=mx.com.caddycab.conductor&hl=es_HN" Target="_blank"> 
                    Descarga <b>Caddy</b> Conductor</a>
                    <img :src="arrow" class="mx-3 img-fluid col-1" style="">
                </div>
            </div>
            </div>
            <div class="row-fluid bg-info" style="border-radius: 100% 100% 0 0 ;">
                <div class="col-12 text-center justify-content-center d-flex align-items-center my-sm-2 my-1">  
                    <span class="text-white description-xs my-3"  style="line-height: 1.8; letter-spacing: 1px;">
                    </span>
                </div> 
            </div>
        </div>
        
        <div class="row d-flex m-0">
            <div class="col-lg-4 col-12 mt-3 d-flex flex-column justify-content-center">
                <div class="m-xl-4 m-2 text-center">
                    <h3 class="title-sm lineup">
                        Sector donde ya operamos
                    </h3> 
                    <span class="text-success description-xs lineup"  style="line-height: 1.5;">
                    Actualmente Caddy ya ofrece el servicio en el sector norte cubriendo colonias como Riberas, 
                    Vistas del Norte, Olivos, Palma Real y colonias aledañas. Contando con una cartelera de más 
                    de 7,000 usuarios entre call center y app móvil.
                    </span>
                </div>
            </div>
        <div class="col-lg-8 col-12">
            <div class="m-xl-5 m-3">
                <img :src="home_main2" class="img-fluid">
            </div>
            
        </div>
        </div>
        
        <div class="row d-flex m-0">
            <div class="col-lg-8 col-12 d-flex flex-column justify-content-center">
                <div class="m-4 text-center">
                    <div class="row">      
                        <div class="col-12">
                            <Carousel :items-to-show="3.5" :wrap-around="true" :autoplay="2000">
                                <Slide v-for="(homesli, index) in galleryhomemn" :key="index">
                                <div class="m-1">
                                <img class="img-fluid imghover4" :src="homesli.img">
                                </div>
                                </Slide>

                                <template #addons>
                                </template>
                            </Carousel>
                        </div>
                    </div>      
                    <h4 class="text-dark description-sm lineup" style="line-height: 1;">
                        Caddy entiende las necesidades de los demás como si fueran propias. 
                        Por ello diseñamos un servicio que solucione las necesidades de 
                        nuestros usuarios al momento de solicitar su Caddy.
                    </h4>
                </div>
            </div>
            <div class="col-lg-4 col-12 mb-3">
                <div class="mx-5 text-lg-end text-center">
                    <h3 class="title-sm lineup">
                        Próximas aperturas
                    </h3>
                </div>    
                    <br>
                    <br>
                <div class="me-lg-5 pe-lg-5 text-lg-end text-center">
                    <h4 class="text-success description-sm lineup">
                        <b>Av Imperio<br>
                        Sahuaros<br>
                        Palmas<br>
                        Pistolas Meneses<br>
                        Desarrollo Industrial<br>
                        Vida Digna<br>
                        Ponce de León</b>
                    </h4>
                </div>
            </div>
           
        </div>
        <div class="row m-0 bg-primary">
            <div class="col-12">
                <div class="title-sm mx-md-3 mt-md-5 mt-3 px-5 text-white text-center">
                <h3>Contamos con 3 Servicios</h3> 
                </div>
            </div>
            <div class="row d-flex px-lg-5 px-3 pb-4">
                <div class="col-md-4 text-center">
                    <div class="mx-lg-4 mx-2 my-3 d-flex align-items-center justify-content-center" style="border-radius: 15px;">
                        <img :src="circle1" class="mt-2 img-fluid imghover" style="max-height: 120px;">
                    </div>
                    <h4 class="text-white description-sm">
                        <b>Centro de <br>Llamadas</b>
                    </h4>
                </div>
                <div class="col-md-4 text-center">
                    <div class="mx-lg-4 mx-2 my-3 d-flex align-items-center justify-content-center" style="border-radius: 15px;">
                        <img :src="circle2" class="mt-2 img-fluid imghover" style="max-height: 120px;">
                    </div>
                    <h4 class="text-white description-sm">
                        <b>Aplicación <br>de usuario</b>
                    </h4>
                </div>
                <div class="col-md-4 text-center">
                    <div class="mx-lg-4 mx-2 my-3 d-flex align-items-center justify-content-center" style="border-radius: 15px;">
                        <img :src="circle3" class="mt-2 img-fluid imghover" style="max-height: 120px;">
                    </div>
                    <h4 class="text-white description-sm">
                        <b>Agenda tu <br>Servicio</b>
                    </h4>
                </div>
            </div>
            <div class="row-fluid bg-info" style="border-radius: 100% 100% 0 0 ;">
                <div class="col-12 text-center justify-content-center d-flex align-items-center my-sm-2 my-1">  
                    <span class="text-white description-xs my-3"  style="line-height: 1.8; letter-spacing: 1px;">
                    </span>
                </div> 
            </div>
        </div>
        <div class="col-12 d-flex justify-content-center">
            <div class="row m-0 d-flex align-items-center m-md-5 m-2">
            <div class="col-2">
                <img :src="phone" class="img-fluid" alt="Caddy Cab">
            </div>
            <div class="col-10">
                <img :src="homepic" class="img-fluid px-md-5 px-2" alt="Caddy Cab">
            </div>
        </div>    
        </div>
        <div class="row d-flex bg-primary m-0">
            <div class="col-4 mt-3">
                <div class="text-center text-white m-2 title-sm">
                    Contáctanos
                </div>
            </div>
            <div class="col-12">
                <div class="row flex-center-center mx-md-5 px-md-5 px-1">
                    <div class="col-12" v-if="!sended_email">
                        <div class="row justify-content-center description-xxs mt-3">
                            <div class="col-sm-4 col-12">
                                <input v-model="name" type="text" placeholder="Nombre" class="form-control my-2" />
                            </div>

                            <div class="col-sm-4 col-12">
                                <input v-model="email" type="email" placeholder="Apellidos" class="form-control my-2" />
                            </div>

                            <div class="col-sm-4 col-12">
                                <input v-model="email" type="email" placeholder="Email" class="form-control my-2" />
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center description-xxs">
                            <div class="col-sm-4 col-12">
                                <input v-model="name" type="text" placeholder="Teléfono" class="form-control my-2" />
                            </div>
                            <div class="col-sm-4 col-12">
                                <input v-model="email" type="email" placeholder="Ciudad" class="form-control my-2" />
                            </div>

                            <div class="col-sm-4 col-12">
                                <input v-model="email" type="email" placeholder="Municipio" class="form-control my-2" />
                            </div>
                        </div>
                        <div class="row justify-content-end description-xxs mb-3">
                            <div class="col-sm-4 col-6 my-2">
                                <div class="text-end text-white m-2">
                                    Al dar “click en registrar” aceptas nuestros términos
                                y condiciones de privacidad
                                </div>
                            </div>
                            <div class="col-sm-4 col-6 my-2">
                                <button @click="sendMail()" type="button" class="btn btn-dark buttonshine w-100" >
                                    <div class="text-decoration-none text-white m-2">
                                        Enviar
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-fluid bg-info" style="border-radius: 100% 100% 0 0 ;">
                <div class="col-12 text-center justify-content-center d-flex align-items-center my-sm-2 my-1">  
                    <span class="text-white description-xs my-3"  style="line-height: 1.8; letter-spacing: 1px;">
                    </span>
                </div> 
            </div>
        </div>
        <div class="col-12 mt-3">
            <div class="d-flex justify-content-center title-sm mx-5 text-success">
                <b>Más información</b>
            </div>
            <div class="d-flex justify-content-center description-lg mx-5 text-dark">
                <b>614 606 7566</b>
            </div>
        </div>
        <div class="row-fluid">
            <div class="col-12">
            </div>
        </div>
        <div class="row-fluid">
            <div class="col-12">
            </div>
        </div>
    </div>
    </div>
</template>
<script setup>
    import { ref } from 'vue'
    import { Carousel, Navigation, Slide } from 'vue3-carousel'

    const arrow = require("ASSETS/images/home/caddyarrow")

    const circle1 = require("ASSETS/images/home/circlehome1.webp")
    const circle2 = require("ASSETS/images/home/circlehome2.webp")
    const circle3 = require("ASSETS/images/home/circlehome3.webp")

    const galleryhomemn = ref([ 
        {img: ref(require('ASSETS/images/home/galleryhome1.webp'))}, 
        {img: ref(require('ASSETS/images/home/galleryhome2.webp'))}, 
        {img: ref(require('ASSETS/images/home/galleryhome3.webp'))},
        {img: ref(require('ASSETS/images/home/galleryhome4.webp'))}
    ])

    const mnmap = require("ASSETS/images/bgmap")
    
    const home_slider1 = require("ASSETS/images/home/home_slider1.webp") 
    const home_slider2 = require("ASSETS/images/home/home_slider2.webp")

    const homepic = require("ASSETS/images/home/homepicture.webp") 
    const phone = require("ASSETS/images/home/phonehome.webp")

    const home_main2 = require("ASSETS/images/home/50.webp")

    const galleryhome = require("ASSETS/images/home/galleryhome.webp")


    const logoblue = require("ASSETS/images/home/caddyblue.webp")
    const logopurple = require("ASSETS/images/home/caddypurple.webp")


    const imgbg = require("ASSETS/images/home/homemn.webp") 
    const car = require("ASSETS/images/home/carleft.webp")
    const imgleft = require("ASSETS/images/home/homeleft.webp")

    const store1 = require("ASSETS/images/awhitestore") 
    const store2 = require("ASSETS/images/gblackstore") 

    const imgbg2 = require("ASSETS/images/home/homemn2.webp")

    const homeslider = [
        {img: home_slider1 , text: "Nuestra misión es mejorar el transporte innovando y llevándolo a cada rincón de la ciudad. No sólo a aquellos que descarguen nuestro aplicativo si no también a aquellos que no lo tengan" },
        {img: home_slider2 , text: "La visión de Caddy es ser la empresa líder en ofrecer el mejor transporte rápido, económico y eficiente de tu ciudad. Entendemos las necesidades como si fueran propias."}
        ]


</script>

<style scoped lang="scss">
    @media screen and (max-width: 600px) {
        .chihuahua {
            font-size: 20px; /* Cambia el tamaño de la fuente según tus necesidades */
        }
    }
    @media screen and (max-width: 576px) {
        .hmain {
            max-height: 75%!important; /* Cambia el tamaño de la fuente según tus necesidades */
        }
    }
    @media screen and (max-width: 576px) {
        .imgapp {
            max-width: 80%!important; /* Cambia el tamaño de la fuente según tus necesidades */
        }
    }
    @media screen and (max-width: 620px) {
        .text1 {
            font-size: 20px; /* Cambia el tamaño de la fuente según tus necesidades */
        }
        .text2 {
            font-size: 15px; /* Cambia el tamaño de la fuente según tus necesidades */
        }
        .appicon {
            width: 40%; /* Cambia el tamaño de la fuente según tus necesidades */
        }
    }
    @media screen and (max-width: 500px) {
        .text1 {
            font-size: 17px; /* Cambia el tamaño de la fuente según tus necesidades */
        }
        .text2 {
            font-size: 12px; /* Cambia el tamaño de la fuente según tus necesidades */
        }
    }
    @media screen and (max-width: 425px) {
        .text1 {
            font-size: 13px; /* Cambia el tamaño de la fuente según tus necesidades */
        }
        .text2 {
            font-size: 8px; /* Cambia el tamaño de la fuente según tus necesidades */
        }
    }
    .apphover:hover{
        transform: scale(1.1);
        cursor: pointer;

    } 
    .car_left {
    transition: transform 2s ease; /* Transición suave de 0.3 segundos */
    cursor: pointer;
    }

    .car_left:hover {
        transform: translateX(-150%); /* Desplazar hacia la izquierda un 75% cuando el cursor está sobre la imagen */
    }
</style>
