<template>
    <div class="container-fluid m-0 p-0" id="home">
        <img :src="mnmap" alt="Imagen de fondo" style="position: fixed;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;opacity: 0.05;z-index: -1;">
        <div class="row d-flex m-0">
                <div class="col-12 mt-5">
                    <div class="d-flex justify-content-center m-md-5 m-3 text-center">
                        <h3 class="m-2 lineup description-md">En <b>Caddy</b>, nuestro compromiso es brindarte 
                        la mejor experiencia en servicio de transporte. Nos enorgullece ofrecer un servicio que 
                        es rápido, eficiente y económico, asegurando que cada uno de tus traslados sea placentero 
                        y sin complicaciones.
                        </h3>
                    </div>
                </div>

                <div class="col-12">
                    <div class="d-flex justify-content-center text-center">
                        <h3 class="mt-1 lineup title-sm">Contamos con 3 Servicios</h3>
                    </div>
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="col-lg-4 col-md-6 col-12">
                        <div class="p-xl-5 p-3 d-flex justify-content-center flex-column">
                            <img class="rounded-circle img-fluid rotateimg" :src="circle3">
                            <h3 class="text-center p-md-2 p-1 title-sm text-primary ">
                                <b>Centro de llamadas</b>
                            </h3>
                            <h4 class="text-center px-lg-3 px-1 description-xs">
                                Los usuarios al llamar al centro de llamadas podrá solicitar su servicio al agente telefónico.
                                El agente capturara la información y la agendará al conductor más cercano
                            </h4>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <div class="p-xl-5 p-3 d-flex justify-content-center flex-column">
                            <img class="rounded-circle img-fluid rotateimg" :src="circle1">
                            <h3 class="text-center p-md-2 p-1 title-sm text-primary">
                                <b>Aplicación de Usuario</b>
                            </h3>
                            <h4 class="text-center px-lg-3 px-1 description-xs">
                                Los usuarios podrán visualizar en tiempo real las unidades disponibles en su sector. 
                                podrán cotizar y solicitar su unidad.
                            </h4>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <div class="p-xl-5 p-3 d-flex justify-content-center flex-column">
                            <img class="rounded-circle img-fluid rotateimg" :src="circle2">
                            <h3 class="text-center p-md-2 p-1 title-sm text-primary">
                                <b>Agenda tu servicio</b>
                            </h3>
                            <h4 class="text-center px-lg-3 px-1 description-xs">
                                Los usuarios podrán agendar su servicio en la hora y día que lo necesiten.
                            </h4>
                        </div>
                    </div>
                </div>
            
            <div class="row-fluid">
                <div class="col-12">
                    <div class="d-flex justify-content-center m-md-4 m-3 text-center">
                        <h3 class="m-2 description-md">
                            En <b>Caddy</b>, no solo ofrecemos un servicio transporte, sino una solución integral para tus 
                            necesidades de movilidad en Chihuahua. Ya sea que estés yendo al aeropuerto, a una reunión 
                            importante o simplemente explorando la ciudad, confía en nosotros para llevarte allí de manera 
                            segura y eficiente. ¡Descubre la diferencia con <b>Caddy</b> hoy mismo!
                        </h3>
                    </div>
                </div>
            </div>

                <div class="col-12">
                    <div class="d-flex title-sm m-md-5 m-3 text-start">
                        <div class="m-2">Características Destacadas:
                        </div>
                    </div>
                    <div class="d-flex align-items-center description-sm m-md-4 m-2 text-start ">
                        <img :src="logoblue" class="m-2" style="max-height: 70px;"><div class="m-2"><b class="description-md">1. Unidades de Reciente Modelo:</b><br>
                            Viaja con estilo y comodidad en nuestras unidades de transporte, todas de reciente modelo y 
                            cuidadosamente mantenidas para garantizar tu seguridad y confort.
                        </div>
                    </div>
                    <div class="d-flex align-items-center description-sm m-md-4 m-2 text-start ">
                        <img :src="logoblue" class="m-2" style="max-height: 70px;"><div class="m-2"><b class="description-md">2. Reglamento Interno Específico:</b><br>
                            Cada flotilla que opera en diferentes sectores de la ciudad sigue un riguroso reglamento interno. 
                            Esto asegura la consistencia en la calidad del servicio y la conformidad con los estándares más altos en cada viaje.
                        </div>
                    </div>
                    <div class="d-flex align-items-center description-sm m-md-4 m-2 text-start ">
                        <img :src="logoblue" class="m-2" style="max-height: 70px;"><div class="m-2"><b class="description-md">3. Seguridad Garantizada:</b><br>
                            La seguridad es nuestra prioridad. Nuestros conductores son cuidadosamente seleccionados, y trabajan 
                            bajo la supervisión constante de agentes telefónicos que monitorean activamente cada flotilla en operación.
                        </div>
                    </div>
                    <div class="d-flex align-items-center description-sm m-md-4 m-2 text-start ">
                        <img :src="logoblue" class="m-2" style="max-height: 70px;"><div class="m-2"><b class="description-md">4. Supervisión Continua:</b><br>
                            Nuestra supervisión no se detiene. Agentes telefónicos capacitados monitorean en tiempo real cada 
                            movimiento de nuestras flotillas, asegurándose de que cumplamos con los más altos estándares de seguridad y eficiencia.
                        </div>
                    </div>
                    <div class="d-flex align-items-center description-sm m-md-4 m-2 text-start ">
                        <img :src="logoblue" class="m-2" style="max-height: 70px;"><div class="m-2"><b class="description-md">5. Compromiso con la Calidad:</b><br>
                            En Caddy, nos comprometemos a proporcionar un servicio que supera tus expectativas. Desde la reserva hasta el destino, 
                            nos esforzamos por brindar la mejor experiencia de transporte posible.
                        </div>
                    </div>
                </div>
            <div class="col-12"> 
                <div class="row d-flex mx-lg-4 mx-0 px-md-4 px-1">
                    <div class="col-12">
                    <Carousel :items-to-show="3" :wrap-around="true" :autoplay="4000">
                        <Slide v-for="(homesli, index) in galleryphone" :key="index">
                        <div class="m-md-2 m-1">
                        <img class="img-fluid imghover4" :src="homesli.img">
                        </div>
                        </Slide>

                        <template #addons>
                        </template>
                    </Carousel>
                </div>
                </div>
            </div>  
            <div class="col-12 mt-3">
                <div class="d-flex justify-content-center description-md m-md-5 m-3 text-center">
                    <div class="m-2">En <b>Caddy</b>, no solo ofrecemos transporte, ofrecemos una experiencia. 
                    Confía en nosotros para hacer de cada traslado una experiencia placentera y segura. 
                    ¡Somos tu mejor opción en servicio de transporte en Chihuahua!.
                    </div>
                </div>
            </div>
            <div class="col-12">
                    <div class="d-flex title-sm m-md-5 m-3 text-start">
                        <div class="m-2">Cómo elegirnos:
                        </div>
                    </div>
                    <div class="d-flex align-items-center description-sm m-md-5 m-3 text-start ">
                        <img :src="logoblue" class="m-2" style="max-height: 70px;"><div class="m-2"><b class="description-md">1. Reserva Fácil y Rápida:</b><br>
                            Utiliza nuestra plataforma de reserva fácil de usar para solicitar tu transporte en solo unos clics.
                        </div>
                    </div>
                    <div class="d-flex align-items-center description-sm m-md-5 m-3 text-start ">
                        <img :src="logoblue" class="m-2" style="max-height: 70px;"><div class="m-2"><b class="description-md">2. Conductores Profesionales:</b><br>
                            Nuestros conductores están capacitados, son profesionales y están comprometidos a ofrecer un servicio amable y cortés en cada viaje..
                        </div>
                    </div>
                    <div class="d-flex align-items-center description-sm m-md-5 m-3 text-start ">
                        <img :src="logoblue" class="m-2" style="max-height: 70px;"><div class="m-2"><b class="description-md">3. Monitoreo en Tiempo Real:</b><br>
                            La seguridad es clave. Nuestra supervisión en tiempo real garantiza que estés en buenas manos en cada momento de tu viaje.
                        </div>
                    </div>
                    <div class="d-flex align-items-center description-sm m-md-5 m-3 text-start ">
                        <img :src="logoblue" class="m-2" style="max-height: 70px;"><div class="m-2"><b class="description-md">4. Tarifas Transparentes:</b><br>
                            Sin sorpresas desagradables. Ofrecemos tarifas transparentes y competitivas para que sepas exactamente cuánto pagarás antes de embarcarte en tu viaje
                        </div>
                    </div>
                </div>  
                <cc-apps></cc-apps>
        </div>
    </div>

</template>
<script setup>
    import { ref } from 'vue'
    import { Carousel, Slide } from 'vue3-carousel'

    const galleryphone = ref([ 
        {img: ref(require("ASSETS/images/services/imgservices1"))}, 
        {img: ref(require("ASSETS/images/services/imgservices2"))}, 
        {img: ref(require("ASSETS/images/services/imgservices3"))}
    ])

    const circle1 = require("ASSETS/images/services/circleservice2")
    const circle2 = require("ASSETS/images/services/circleservice3")
    const circle3 = require("ASSETS/images/services/circleservice1")

    const mnmap = require("ASSETS/images/bgmap")

    const logoblue = require("ASSETS/images/home/caddyblue.webp")

</script>

<style scoped lang="scss">
</style>
