<template>
    <div class="container-fluid m-0 p-0" id="home">
        <img :src="mnmap" alt="Imagen de fondo" style="position: fixed;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;opacity: 0.05;z-index: -1;">
        <div class="row d-flex m-0">
            <div class="col-lg-6 col-12 d-flex flex-column">
                <div class="title-md mt-5 text-lg-start text-center mx-md-5 mx-2">
                    <div class="mt-5 lineup">Contacto</div>
                </div>
                <div class="description-md mt-lg-5 mt-2 text-lg-start text-center mx-md-5 mx-2 line lineup">
                    <b>Póngase en contacto con nosotros</b>
                </div>
                <div class="description-md m-lg-5 m-3 text-lg-start text-center text-center lineup">
                    <div>Póngase en contacto con nosotros
                    ¿Tiene alguna pregunta, consulta o comentario? Siéntete libre de contactarnos. 
                    Simplemente complete el formulario de contacto o simplemente seleccione su canal preferido y envíenos un mensaje. 
                    Haremos todo lo posible para responder rápidamente.
                    <br><br>            
                    <b>Río Ebro 2020, Chihuahua, Mexico</b>
                    <br><br>      
                    caddycabmexico@gmail.com
                    <br><br>          
                    <b>614 470 8296</b>
                    </div>
                </div>
            </div>
                <div class="col-lg-6 col-12 d-flex flex-column mt-lg-5">
                    <img class="img-fluid mt-lg-5 px-4" :src="maincontact" alt="Caddy Cab">
                    <iframe class="mt-lg-5 mt-2 px-4" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.8314618873646!2d-106.16956162433138!3d28.771247777767613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ea6a9baa5e97bb%3A0x9ec8b0a4bfe85e54!2sR%C3%ADo%20Ebro%202020%2C%2031184%20Chihuahua%2C%20Chih.!5e1!3m2!1ses-419!2smx!4v1706228581960!5m2!1ses-419!2smx" 
                    width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        <div class="row-fluid my-3">
            <div class="col-md-4 col-12 mx-md-5 bg-primary">
                <div class="text-center text-white m-2 title-sm">
                    Contacto
                </div>
            </div>
            <div class="col-12">
                <div class="row flex-center-center mx-md-5 mx-1 px-md-5 px-2 bg-primary">
                    <div class="col-12" v-if="!sended_email">
                        <div class="row justify-content-center description-xxs mt-3">
                            <div class="col-sm-4 col-12">
                                <input v-model="name" type="text" placeholder="Nombre" class="form-control my-2" />
                            </div>

                            <div class="col-sm-4 col-12">
                                <input v-model="email" type="email" placeholder="Apellidos" class="form-control my-2" />
                            </div>

                            <div class="col-sm-4 col-12">
                                <input v-model="email" type="email" placeholder="Email" class="form-control my-2" />
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center description-xxs">
                            <div class="col-sm-4 col-12">
                                <input v-model="name" type="text" placeholder="Teléfono" class="form-control my-2" />
                            </div>
                            <div class="col-sm-4 col-12">
                                <input v-model="email" type="email" placeholder="Ciudad" class="form-control my-2" />
                            </div>

                            <div class="col-sm-4 col-12">
                                <input v-model="email" type="email" placeholder="Municipio" class="form-control my-2" />
                            </div>
                        </div>
                        <div class="form-outline description-xxs">
                            <textarea v-model="message" class="form-control my-2" placeholder="Mensaje" rows="5"></textarea>
                        </div>
                        <div class="row justify-content-end description-xxs mb-3">
                            <div class="col-sm-4 col-6 my-2">
                                    <div class="text-end text-white m-2">
                                    Al dar “click en registrar” aceptas nuestros términos
                                    y condiciones de privacidad
                                    </div>
                            </div>
                            <div class="col-sm-4 col-6 my-2">
                                <button @click="sendMail()" type="button" class="btn btn-dark buttonshine w-100" >
                                    <div class="text-decoration-none text-white m-2">
                                        Enviar
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <cc-apps></cc-apps>
    </div>

</template>
<script setup>
    const mnmap = require("ASSETS/images/bgmap")
    const maincontact = require("ASSETS/images/contact/mncontact")

</script>

<style scoped lang="scss">
</style>
