<template >
    <div class="col-12 d-flex flex-center flex-column">
        <div class="d-flex justify-content-center">
            <img :src="logopurple" class="imghover2">
        </div>
        <div class="row-fluid d-flex justify-content-center ">
            <a class="d-flex justify-content-center w-25 mx-3"><img :src="store1" class="img-fluid m-md-5 m-2 imghover2" style=""></a>
            <a href="https://play.google.com/store/apps/details?id=mx.com.caddycab.cliente" Target="_blank" class="d-flex justify-content-center w-25 mx-3"><img :src="store2" class="img-fluid m-md-5 m-2 imghover2" style=""></a>
        </div>
    </div>
</template>
<script setup>
    import { ref } from "vue";
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    const logopurple = require("ASSETS/images/home/caddypurple.webp")

    const store1 = require("ASSETS/images/awhitestore") 
    const store2 = require("ASSETS/images/gblackstore") 

</script>
<style scoped lang="scss">


</style>