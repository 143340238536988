<template>
    <header class="container-fluid position-fixed shadow-sm bg-primary" style="height: 60px;  z-index: 99;">
            <div class="flex-between h-100">
                <div class="flex-center h-100 mx-xl-2">
                    <div class="text-center text-secondary ms-3">
                        <div class="text-secondary ms-xl-4 me-3 ">
                            <img :src="caddymn" class="img-fluid m-3 imghover" @click="navigateToHome" style="max-height: 60px;" >
                        </div>
                    </div>
                    <div class="text-center m-xl-3 m-2 d-none d-lg-block navhover">  
                        <span class="text-white description-xs" style="font-size: 1vw;" @click="navigateToUs">
                            <b>NOSOTROS</b>
                        </span>
                    </div> 
                    <div class="text-center m-xl-3 m-2 d-none d-lg-block navhover">  
                        <span class="text-white description-xs" style="font-size: 1vw;" @click="navigateToDownload">
                            <b>DESCARGA</b>
                        </span>
                    </div> 
                    <div class="text-center m-xl-3 m-2 d-none d-lg-block navhover">  
                        <span class="text-white description-xs" style="font-size: 1vw;" @click="navigateToOperation">
                            <b>OPERACIÓN</b>
                        </span>
                    </div>
                    <div class="text-center m-xl-3 m-2 d-none d-lg-block navhover">  
                        <span class="text-white description-xs" style="font-size: 1vw;" @click="navigateToOpenings">
                            <b>APERTURAS</b>
                        </span>
                    </div> 
                    <div class="text-center m-xl-3 m-2 d-none d-lg-block navhover">  
                        <span class="text-white description-xs" style="font-size: 1vw;" @click="navigateToServices">
                            <b>SERVICIOS</b>
                        </span>
                    </div> 
                    <div class="text-center m-xl-3 m-2 d-none d-lg-block navhover">  
                        <span class="text-white description-xs" style="font-size: 1vw;" @click="navigateToDriver">
                            <b>CONDUCTOR</b>
                        </span>
                    </div> 
                    <div class="text-center m-xl-3 m-2 d-none d-lg-block navhover">  
                        <span class="text-white description-xs" style="font-size: 1vw;" @click="navigateToContact">
                            <b>CONTACTO</b>
                        </span>
                    </div> 
                </div>
                <div id="myModal" class="modalmn d-lg-none" v-show="showModal">
                    <div class="modal-content">
                        <div class="d-flex  align-items-center justify-content-center">
                            <div class="d-flex flex-column text-uppercase text-primary text-center description-xs fw-bold">
                                <div class="my-2 imghover" @click="navigateToUs">NOSOTROS</div>
                                <div class="my-2 imghover" @click="navigateToDownload">DESCARGA</div>
                                <div class="my-2 imghover" @click="navigateToOperation">OPERACIÓN</div>
                                <div class="my-2 imghover" @click="navigateToOpenings">APERTURAS</div>
                                <div class="my-2 imghover" @click="navigateToServices">SERVICIOS</div>
                                <div class="my-2 imghover" @click="navigateToDriver">CONDUCTOR</div>
                                <div class="my-2 imghover" @click="navigateToContact">CONTACTO</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-center h-100 mx-xl-5">
                    <div style="margin-right: auto;">
                        <a class="text-center ms-2 d-lg-none d-block" @click="showModal = !showModal">
                            <img :src="navicon" class="img-fluid navicon m-2" style="max-height: 40px; z-index: 101;">
                        </a>
                    </div>
                    <div class="text-center m-xl-4 m-3 d-none d-lg-block imghover">  
                        <span class="text-dark description-xs" style="font-size: 1vw;">
                            <b>
  
                            </b>
                        </span>
                    </div> 
                    <!--<button type="button" class="btn btn-success m-2 d-none d-lg-block buttonshine" >
                            <a class="text-decoration-none text-white description-xxs">
                                DESCARGA
                            </a>
                        </button>-->
                </div>
        </div>
    </header>

</template>
<script setup>
import { ref } from "vue";
import { useRouter } from 'vue-router'
import { useStore } from "vuex"

const caddymn = require("ASSETS/images/CaddyLogo") 
const navicon = require("ASSETS/icons/navicon")

const router = useRouter()
const store = useStore()

const showModal = ref(false);
const showIcon = ref(false);

    const navigateToHome = () => {
        router.push('/');
        window.scrollTo(0, 0); 
    }
    const navigateToContact = () => {
        router.push('/contacto');
        window.scrollTo(0, 0); 
    }
    const navigateToDownload = () => {
        router.push('/descarga');
        window.scrollTo(0, 0); 
    }
    const navigateToDriver = () => {
        router.push('/conductor');
        window.scrollTo(0, 0); 
    }
    const navigateToOperation = () => {
        router.push('operacion');
        window.scrollTo(0, 0); 
    }
    const navigateToServices = () => {
        router.push('/servicios');
        window.scrollTo(0, 0); 
    }
    const navigateToUs = () => {
        router.push('/nosotros');
        window.scrollTo(0, 0); 
    }
    const navigateToOpenings = () => {
        router.push('/aperturas');
        window.scrollTo(0, 0); 
    }



</script>
<style lang="scss" scoped>
.imghover:hover{
    transform: scale(1.2);
    cursor: pointer;

} 
.navhover:hover{
    transform: scale(1.1);
    border-bottom: 1px solid #ffffff;
    border-top: 1px solid #ffffff;
    cursor: pointer;

} 

.buttonshine:hover {
    animation-name: shine;
    animation-duration: 310ms;
    transform: scale(1.1);
    box-shadow: 6px 6px 6px #9a9a9a;
    background-color: #7C9895;   
}

.buttonshine2:hover {
    animation-name: shine;
    animation-duration: 310ms;
    transform: scale(1.1);
    box-shadow: 6px 6px 6px #9a9a9a;
    background-color: #7C9895;
}
.buttonshine2:hover a {
/* Cambio de color para el texto cuando el mouse está sobre el botón */
color: white;
}  
.navicon{
    transform: scale(1.0);
    cursor: pointer;
    border-radius: 5px;
    background-color: #ffffff;
} 
.navicon:hover{
    transform: scale(1.4);
    cursor: pointer;
}  


@keyframes shine {
0% {
    background: linear-gradient(
    30deg, 
    hsl(260, 85%, 95%) 0%, 
    #7C9895 25%
    );
}

25% {
    background: linear-gradient(
    30deg, 
    #7C9895 0%, 
    hsl(260, 85%, 95%) 25%, 
    #7C9895 50%);
}

50% {
    background: linear-gradient(
    30deg,
    #7C9895 0%, 
    hsl(260, 85%, 95%) 50%, 
    #7C9895 75%); 
}

75% {
    background: linear-gradient(
    30deg, 
    #7C9895 0%, 
    hsl(260, 85%, 95%) 75%, 
    #7C9895 100%); 
}

100% {
    background: linear-gradient(
    30deg, 
    #7C9895 0%, 
    hsl(260, 85%, 95%) 100%); 
    }
} 
.modalmn {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.095); /* Fondo oscuro semi-transparente */
}
.modal-content {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffffe8; /* Fondo blanco */
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.044);
}
.btn-no {
    width: 50px;  
    height: 50px;    
    background-color: #ffffff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
cursor: pointer;
}
.btn-si {
    width: 70%;      
    background-color: #7FDAE0;
    color: white;
    border: none;
    padding: 10px 10px;
    border-radius: 10px;
cursor: pointer;
}


</style>