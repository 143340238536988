<template>
    <div class="container-fluid m-0 p-0" id="home">
        <img :src="mnmap" alt="Imagen de fondo" style="position: fixed;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;opacity: 0.05;z-index: -1;">
        <div class="row d-flex mx-lg-5 mx-2">
            <div class="col-md-7 col-12 d-flex">
                <div class="d-flex mx-md-5 mx-2 px-lg-5 px-2 mt-5 pt-md-5 flex-column text-start">
                    <h3 class="mt-5 lineup title-sm">
                        <b>Escanéa el QR <br>con tu celular</b>
                    </h3>
                    <h4 class="description-xs mt-3 lineup">Ahora podrás viajar con la confianza y calidad que te ofrece Caddy Cab.
                        Conductores profesionales y verificados para que disfrutes cada uno de tus viajes.
                    </h4>
                </div>  
            </div>
                <div class="col-md-5 col-12">
                    <div class="d-flex justify-content-center mx-xl-5 mx-2 px-lg-5 px-2 mt-5 pt-md-5">
                        <img class="img-fluid" :src="code" alt="Caddy Cab">
                    </div>
                </div>
            </div>
            <div class="row d-flex mx-lg-5 mx-3 px-md-5 px-2 pt-5">
                <div class="col-12">
                    <Carousel :items-to-show="3" :wrap-around="true" :autoplay="4000">
                        <Slide v-for="(homesli, index) in galleryphone" :key="index">
                        <div class="m-md-3 m-1">
                        <img class="img-fluid imghover4" :src="homesli.img" alt="Caddy Cab">
                        </div>
                        </Slide>

                        <template #addons>
                        </template>
                    </Carousel>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-12">
                    <div class="d-flex justify-content-center m-md-5 m-3 text-center">
                        <h4 class="m-2 lineup description-md">Desde el centro de la ciudad hasta los suburbios, 
                        Caddy cubre toda la extensión de Chihuahua. No importa a dónde vayas, 
                        estamos aquí para llevarte.
                        </h4>
                    </div>
                </div>
            </div>
            <cc-apps></cc-apps>
    </div>

</template>
<script setup>
    import { ref } from 'vue'
    import { Carousel, Slide } from 'vue3-carousel'

    const galleryphone = ref([ 
        {img: ref(require("ASSETS/images/download/1r"))}, 
        {img: ref(require("ASSETS/images/download/2r"))},
        {img: ref(require("ASSETS/images/download/3r"))}, 
        {img: ref(require("ASSETS/images/download/4r"))},
        {img: ref(require("ASSETS/images/download/5r"))}, 
        {img: ref(require("ASSETS/images/download/6r"))},
        {img: ref(require("ASSETS/images/download/7r"))}, 
        {img: ref(require("ASSETS/images/download/8r"))}, 
        {img: ref(require("ASSETS/images/download/9r"))}
    ])


    const mnmap = require("ASSETS/images/bgmap")

    const code = require("ASSETS/images/download/code")
    


</script>

<style scoped lang="scss">
</style>