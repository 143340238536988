module.exports = {
	env: "local",
	server: {
		local: {
			page_url: 'http://localhost:8080',
			api: 'http://localhost:3101',
			headers: {
				'Accept': 'application/json, text/plain, /',
            	'Content-Type': 'application/json',
            	'Authorization': 'Bearer ',
				'reference': 'com.caddy.local'
			}
		},
		dev: {
			page_url: 'https://monocilindrero.com/caddy',
			api: 'https://api.monocilindrero.co',
			headers: {
				'Accept': 'application/json, text/plain, /',
            	'Content-Type': 'application/json',
            	'Authorization': 'Bearer ',
				'reference': 'com.caddy.dev'
			}
		},
		prod: {
			page_url: 'https://caddy.com/',
			api: 'https://api.caddy.com/',
			headers: {
				'Accept': 'application/json, text/plain, /',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ',
				'reference': 'com.caddy.prod'
			}
		}
	}
}